import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
import { addArticle, articleGoTop, articleList, articleUpOrDown, getAllClassifys, getBrandList, getItemData, handleArticle } from "@/services/articleManage";
import ArticleManege_Table from "./table";
import {
    Select,
    Input,
    Tabs,
    Table,
    Pagination,
    Button,
    Form,
    Upload,
    Radio,
    Row,
    Col,
    Checkbox,
    DatePicker,
    Popconfirm, Image
} from 'antd';
import { getOSSSignature } from "@/services/uplaodImgOss";
import { PlusOutlined, BulbOutlined } from '@ant-design/icons';
import { articleEditorList } from "@/services/article";
import moment from 'moment'
import ArticleManege_Editor from "./editor";
import {addsku, oneList, queryThirdData, skuClassifyList, skuinfo, skuInfoDel, updatesku} from "@/services/oneManage";

const { Option } = Select;
const ArticleManege: React.FC = (props:any) => {
    const [search, setSearch] = useState<any>({
        brandId: null,
        pageNum: 0,
        pageSize: 20,
        status: null,
        title: '',
        type: '全部',
    });
    const [tableTotal, setTableTotal] = useState(0);
    const [typeInfo, setTypeInfo] = useState<any>(null);
    const [twotypeInfo, settwoTypeInfo] = useState<any>(null);
    const [twoClass, setTwoClass] = useState<any>(null);
    const [brandInfo, setBrandInfo] = useState<any>(null);
    const [editorCodeArr, setEditorCodeArr] = useState<any>([])
    const [Data, setData] = useState<any>(null);
    const [nowItem, setNowItem] = useState<any>(props.children?JSON.parse(props.children):null);
    let formRef = useRef<any>()
    const inputRef: any = useRef(null)

    useEffect(() => {
        getAllClassInfo();
        getBrandInfo();
        if(props.children){
            getAllClassInfo('')
        }
    }, [])

    useEffect(() => {
        if (!nowItem) {
            getOneList()
        }


    }, [search, nowItem])

    function getAllClassInfo(oneClassify?:any,type?:any) {
        let param={
            classify:oneClassify,
            recommend:1
        }
        skuClassifyList(param).then(res => {
            let resData = res.datas ? res.datas : [];
            if(oneClassify){
                if(type){
                    setTwoClass(resData)
                }else{
                    settwoTypeInfo(resData)
                }
            }else{
                setTypeInfo(resData)
            }
        })
    }

    function getBrandInfo() {
        getBrandList().then(res => {
            let resData = res.datas;
            resData.unshift({ id: null, name: '全部', subName: '' })
            setBrandInfo(resData)
        })
    }

    function getOneList() {
        oneList({ ...search }).then(res => {
            let resData = res.datas;
            setTableTotal(resData.total);
            setData(resData)
        })
    }

    function renderHeader() {
        function handleChange(type: string, value: number) {
            if(type=='oneClassify'){
                getAllClassInfo(value)
            }
            let search_c = { ...search };
            search_c[type] = value;
            setSearch(search_c)
        }
        function onChangeinput(e: string) {
            let search_c = { ...search };
            search_c.word = e;
            setSearch(search_c)
        }

        return (
            <div className={styles.articleManageHeaderBox}>
                <div className={styles.articleManageHeader}>
                    <div>
                        <div className={styles.title}>品牌：</div>
                        {
                            Boolean(brandInfo) &&
                            <Select showSearch optionFilterProp="children" value={search.brandId} style={{ width: 170 }} onChange={(value: number) => { handleChange('brandId', value) }}>
                                {
                                    brandInfo.map((item: any) => {
                                        return (
                                            <Option value={item.id} key={item.id}>{item.name + '  ' + item.subName}</Option>
                                        )
                                    })
                                }
                            </Select>
                        }
                    </div>
                    <div>
                        <div className={styles.title}>一级分类：</div>
                        {
                            Boolean(typeInfo) &&
                            <Select showSearch optionFilterProp="children" value={search.oneClassify} style={{ width: 170 }} onChange={(value: number) => { handleChange('oneClassify', value) }}>
                                {
                                    typeInfo.map((item: any) => {
                                        return (
                                            <Option value={item.classify} key={item.id}>{item.classify}</Option>
                                        )
                                    })
                                }
                            </Select>
                        }
                    </div>
                    <div>
                        <div className={styles.title}>二级分类：</div>
                        {
                            Boolean(twotypeInfo) &&
                            <Select showSearch optionFilterProp="children" value={search.twoClassify} style={{ width: 170 }} onChange={(value: number) => { handleChange('twoClassify', value) }}>
                                {
                                    twotypeInfo.map((item: any) => {
                                        return (
                                            <Option value={item.classify} key={item.id}>{item.classify}</Option>
                                        )
                                    })
                                }
                            </Select>
                        }
                    </div>


                    <div>
                        <Input value={search ? search.word : ''} onChange={(e) => { onChangeinput(e.target.value) }} placeholder="输入关键词" />
                    </div>
                </div>
                <Button size={'middle'} type='primary' onClick={() => {
                    setNowItem({
                        content: '<p></p>',
                    })
                 }}>新建单品</Button>
            </div>
        )
    }

    function renderTable() {

        function delItem(id: string) {
            skuInfoDel(id).then(res => {
                getOneList()
            })
        }
        function getItem(id: string) {
            skuinfo(id).then(res => {
                let resData = res.datas;
                if (resData.fitCrowd) {
                    resData.fitCrowd = resData.fitCrowd.toString().indexOf(',')!=-1?resData.fitCrowd.split(','):[resData.fitCrowd]
                }
                if (resData.fitSkin) {
                    resData.fitSkin =  resData.fitSkin.toString().indexOf(',')!=-1?resData.fitSkin.split(','):[resData.fitSkin]
                }
                if (!resData.content) {
                    resData.content = '<p></p>'
                }
                getAllClassInfo(resData.oneClassify,1)
                setNowItem(resData)
            })
        }
        const columns: any = [
            {
                title: '头图',
                dataIndex: 'headImgUrl',
                key: 'headImgUrl',
                render: (status: string, record: any) => {
                    return (
                        <Image style={{width:'50px'}} src={record.headImgUrl}/>
                    )
                }
            },
            {
                title: '标题',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '品牌',
                dataIndex: 'brandName',
                key: 'brandName',
            },
            {
                title: '一级分类',
                dataIndex: 'oneClassify',
                key: 'oneClassify',
            },
            {
                title: '二级分类',
                dataIndex: 'twoClassify',
                key: 'twoClassify',
            },
            {
                title: '操作',
                dataIndex: 'id',
                width: 170,
                key: 'id',
                render: (id: any, record: any) => {
                    return (
                        <div className={styles.caozuoBtn}>
                            <a onClick={() => { getItem(id) }}>修改</a>
                            <Popconfirm placement="top" title={'确定删除吗'} onConfirm={()=>{delItem(id) }} okText="Yes" cancelText="No">
                                <a href='#' >删除</a>
                            </Popconfirm>
                        </div>
                    )
                }
            },
        ]
        function onChangePagination(page: number, pageSize: number) {
            let search_c = { ...search };
            search_c.pageSize = pageSize;
            search_c.pageNum = page;
            setSearch(search_c)
        }
        return (
            <div className={styles.table}>
                <Table
                    pagination={false}
                    dataSource={Data ? Data.list : null}
                    columns={columns}
                ></Table>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '2rem' }}>
                    <Pagination
                        showSizeChanger
                        showQuickJumper
                        showTotal={total => `总量： ${total} 条`}
                        pageSizeOptions={[10, 20, 50, 100, 1000]}
                        onChange={(page, pageSize) => { onChangePagination(page, pageSize) }}
                        pageSize={search.pageSize}
                        current={search.pageNum}
                        total={tableTotal}
                    />
                </div>
            </div>
        )
    }

    function renderHandle() {
        const { TextArea } = Input;
        function addItem(num:string) {
            let item = { ...nowItem };
            item.fitCrowd=item.fitCrowd?item.fitCrowd.join(','):''
            item.fitSkin=item.fitSkin?item.fitSkin.join(','):''
            item.content = inputRef.current.changeEditor();
            if (item.id) {
                if(location.href.indexOf('03As1')!=-1){
                    addsku(item).then(res => {
                        window.opener.openArc(res.datas);
                        window.close()
                    })
                }else{
                    updatesku(item).then(res => {
                        setNowItem(null)
                    })
                }

            } else {
                addsku(item).then(res => {
                    if(location.href.indexOf('03As1')!=-1){
                        window.opener.openArc(res.datas);
                        window.close()
                    }else{
                        setNowItem(null)
                    }

                })
            }
        }
        function beforeUpload(file: any) {
            getOSSSignature(file, '', file.name).then(res => {
                onChange_Form_Name('headImgUrl', res.fileURL)
            })
        }
        function getClassInfo(oneClassify?:any) {
            let param={
                classify:oneClassify
            }
            skuClassifyList(param).then(res => {
                let resData = res.datas ? res.datas : [];
                setTwoClass(resData)
            })
        }
        function onChange_Form_Name(type: string, value: any) {
            let item = { ...nowItem };
            item[type] = value;
            setNowItem(item)
            if(type=='oneClassify'){
                getClassInfo(value)
            }
        }

        function searchComposition(){
            queryThirdData({name:nowItem.recordNo}).then(res => {
                let item = { ...nowItem };
                item['composition'] = res.datas.composition;
                setNowItem(item)
            })
        }

        return (
            <div className={styles.articleHandle}>
                <div className={styles.articleHandleHeader}>
                    <div><Button size={'middle'} type='primary' onClick={() => { setNowItem(null) }}>{'<<返回'}</Button></div>
                    <div>
                        <Button style={{marginLeft:'1rem'}} size={'middle'} type='primary' onClick={() => { addItem('1') }}>保存并上架</Button>
                    </div>
                </div>
                <Row>
                    <Col span={12}>
                        <Form
                            ref={formRef}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                            initialValues={nowItem}
                        >
                            <Form.Item label="产品图" name="headImgUrl">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={(file) => beforeUpload(file)}
                                        >
                                            {nowItem && nowItem.headImgUrl ? <img src={nowItem.headImgUrl} alt="avatar" style={{ width: '100px', height: '100px' }} /> :
                                                <PlusOutlined />}
                                        </Upload>
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="产品展示名称" name="name">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.name}
                                        onChange={(e) => { onChange_Form_Name('name', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label="产品显示别名（选填）" name="alias">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input value={nowItem.alias} onChange={(e) => { onChange_Form_Name('alias', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>


                            <Form.Item label="所属品牌" name="brandId">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Select showSearch optionFilterProp="children" value={nowItem.brandId} style={{ width: 170 }} onChange={(value: number) => { onChange_Form_Name('brandId', value) }}>
                                            {
                                                Boolean(brandInfo) &&
                                                brandInfo.map((item: any) => {
                                                    if (item.id) {
                                                        return (
                                                            <Option value={item.id} key={item.id}>{item.name + '  ' + item.subName}</Option>
                                                        )
                                                    }
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="一级分类" name="classify">
                                <div className={styles.fromItem}>
                                    <div>
                                        {Boolean(typeInfo) && <Select showSearch optionFilterProp="children" value={nowItem.oneClassify}
                                                 style={{width: 170}} onChange={(value: any) => {
                                            onChange_Form_Name('oneClassify', value)
                                        }}>
                                            {
                                                typeInfo.map((item: any) => {
                                                    return (
                                                        <Option value={item.classify}
                                                                key={item.id}>{item.classify}</Option>
                                                    )
                                                })
                                            }
                                        </Select>}
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="二级分类" name="classify">
                                <div className={styles.fromItem}>
                                    <div>
                                        {Boolean(twoClass) && <Select showSearch optionFilterProp="children" value={nowItem.twoClassify}
                                                 style={{width: 170}} onChange={(value: any) => {
                                            onChange_Form_Name('twoClassify', value)
                                        }}>
                                            {
                                                twoClass.map((item: any) => {
                                                    return (
                                                        <Option value={item.classify} key={item.id}>{item.classify}</Option>
                                                    )
                                                })
                                            }
                                        </Select>}
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="成分" name="title">
                                <div className={styles.flexRow}>
                                    <Input
                                        type="text"
                                        value={nowItem.recordNo}
                                        placeholder='请输入备案编号（选填）'
                                        onChange={(e) => { onChange_Form_Name('recordNo', e.target.value) }}
                                    />
                                    <Button size={'middle'} type='primary' onClick={()=>{
                                        searchComposition()
                                    }}>搜索</Button>
                                </div>
                                <TextArea placeholder='请输入成分列表（选填）'
                                          value={nowItem.composition} showCount
                                           style={{ width:'100%', height: 120 }}
                                          onChange={(e)=>{onChange_Form_Name('composition', e.target.value) }} />

                            </Form.Item>
                            {/*<Form.Item label="适合肤质" name="fitSkin">*/}
                            {/*    <div className={styles.fromItem}>*/}
                            {/*        <div>*/}
                            {/*            <Checkbox.Group onChange={(e) => { onChange_Form_Name('fitSkin', e) }} value={nowItem.fitSkin}>*/}
                            {/*                <Row>*/}
                            {/*                    <Checkbox value={'1'}>不展示</Checkbox>*/}
                            {/*                    <Checkbox value={'2'}>全部肤质</Checkbox>*/}
                            {/*                    <Checkbox value={'4'}>干皮</Checkbox>*/}
                            {/*                    <Checkbox value={'8'}>混合皮</Checkbox>*/}
                            {/*                    <Checkbox value={'16'}>油皮</Checkbox>*/}
                            {/*                </Row>*/}
                            {/*            </Checkbox.Group>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Form.Item>*/}

                            {/*<Form.Item label="适合人群" name="fitCrowd">*/}
                            {/*    <div className={styles.fromItem}>*/}
                            {/*        <div>*/}
                            {/*            <Checkbox.Group onChange={(e) => { onChange_Form_Name('fitCrowd', e) }} value={nowItem.fitCrowd}>*/}
                            {/*                <Row>*/}
                            {/*                    <Checkbox value={'1'}>不展示</Checkbox>*/}
                            {/*                    <Checkbox value={'2'}>孕妇/备孕</Checkbox>*/}
                            {/*                    <Checkbox value={'4'}>敏感肌</Checkbox>*/}
                            {/*                </Row>*/}
                            {/*            </Checkbox.Group>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Form.Item>*/}
                        </Form>
                    </Col>
                    <Col span={12}>
                        <ArticleManege_Editor ref={inputRef} Data={nowItem ? nowItem.content : null}  />
                    </Col>
                </Row>
            </div>
        )
    }
    return (
        <div className={styles.articleManege}>
            {
                Boolean(!nowItem) &&
                <React.Fragment>
                    {renderHeader()}
                    {renderTable()}
                </React.Fragment>
            }
            {
                Boolean(nowItem) &&
                <React.Fragment>
                    {renderHandle()}
                </React.Fragment>
            }
        </div>
    )
};

export default ArticleManege;
