import {
  responseInterceptor,
  resultType, whyRequest, _getParam
} from "../utils/reuqest";
import {AddArticleType} from "@/services/articleManage";




export const adminList = async (param:{pageNum:number,pageSize:number}) => {
  const reslutData = await whyRequest.get(`/admin/api/btDaily/adminList?${_getParam(param)}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}

export const adminSetIdList = async (params: { id: string,idList:string }) => {
  const reslutData = await whyRequest.get(`/admin/api/btDaily/adminSetIdList?id=${params.id}&idList=${params.idList}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}

export const dailyList = async (params: { idList: string }) => {
  const reslutData = await whyRequest.get(`/admin/api/btDaily/dailyList?idList=${params.idList}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}