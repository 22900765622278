import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
import ArticleManege_Table from "./table";
import {
    Select,
    Input,
    Tabs,
    Table,
    Pagination,
    Button,
    Form,
    Upload,
    Radio,
    Row,
    Col,
    Checkbox,
    DatePicker,
    notification, Popconfirm
} from 'antd';
import { getOSSSignature } from "@/services/uplaodImgOss";
import moment from 'moment'
import {adminList, adminSetIdList} from "@/services/todayManage";

const { Option } = Select;
const TaoManger: React.FC = () => {
    const [search, setSearch] = useState<any>({
        pageNum: 1,
        pageSize: 20,
    });
    const [adminListInfo, setadminList] = useState<any>(null);
    const [nowItem, setNowItem] = useState<any>(null);

    let formRef = useRef<any>()

    useEffect(() => {
        getAdminList();
    }, [])

    useEffect(() => {
        if (!nowItem) {
            getAdminList()
        }
    }, [search, nowItem])

    function getAdminList() {
        adminList(search).then((res:any) => {
            let resData = res.datas.list;
            setadminList(resData)
        })
    }




    function renderHeader() {
        function handleChange(type: string, value: number) {
            let search_c = { ...search };
            search_c[type] = value;
            setSearch(search_c)
        }
        function onChangeinput(e: any) {
            let search_c = { ...search };
            search_c.name = e;
            setSearch(search_c)
        }
        return (
            <div className={styles.articleManageHeaderBox}>
                <div className={styles.articleManageHeader}>
                    <div>
                        <div className={styles.title}></div>
                    </div>
                </div>
            </div>
        )
    }


    function renderTable() {

        function goUp(id: string,idList:string) {
            adminSetIdList({id,idList}).then(res => {
                getAdminList()
            })
        }

        const onChange = (e:any,i:number) => {
            let list=[...adminListInfo]
            list[i]['idList']=e.target.value
            setadminList(list)
        };

        return (
            <div className={styles.table}>
                {Boolean(adminListInfo) && adminListInfo.map((item:any,i:number)=>(<div className={styles.todayTao}>
                    <div className={styles.head}>
                        <span>{item.pushDate}</span>
                        <Button size={'middle'} type='primary' onClick={() => {
                            window.open(`/#/listChild?pushDate=${item.idList}+)`,"ziyemian","width=1024,height=1024,top=0,left=0,toobar=no,menubar=no,scrollbars=yes,location=no,status=no")
                        }}>预览</Button>
                        <Button size={'middle'} type='primary' onClick={() => { goUp(item.id,item.idList) }}>保存</Button>
                        {Boolean(item.pushTime) && <span className={styles.headNmae}>已推送</span>}
                    </div>
                    <div>
                        <Input value={item.idList}  onChange={(e)=>{onChange(e,i)}} placeholder="请输入优惠id，用英文逗号分隔" />
                    </div>
                </div>))}
            </div>
        )
    }


    return (
        <div className={styles.articleManege}>
            {
                Boolean(!nowItem) &&
                <React.Fragment>
                    {renderHeader()}
                    {renderTable()}
                </React.Fragment>
            }

        </div>
    )
};

export default TaoManger;
