import {
  responseInterceptor,
  resultType, whyRequest, _getParam
} from "../utils/reuqest";







export const getTask = async () => {
  const reslutData = await whyRequest.get('/admin/api/task/getTask');
  return responseInterceptor(reslutData) as Promise<resultType>
}



export const transferTask = async (params:any) => {
  const reslutData = await whyRequest.get(`/admin/api/task/transferTask?${_getParam(params)}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}
export const submitTask = async (params:any) => {
  const reslutData = await whyRequest.post(`/admin/api/task/submitTask`, { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}

export const userList = async () => {
  const reslutData = await whyRequest.get('/admin/api/user/list');
  return responseInterceptor(reslutData) as Promise<resultType>
}

