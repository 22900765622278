import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import styles from "./index.module.less";
import { Layout, Menu, message } from "antd";
import { articleList, getAllClassifys } from "@/services/articleManage";
import { checkTpwdExist } from "@/services/article";
import Editor from '../../articleManege/editor/editor.js';
require('./style.less');

let editor:any = null;

const ArticleManege_Editor = React.forwardRef<any,any>((props, ref) => {
    useImperativeHandle(ref, () => ({
        changeEditor:()=>{
            console.log('---')
            return editor.editor.getData()
        }
    }));
    const [Data, setData] = useState<any>(null);
    useEffect(()=>{
        setData(props.Data)
    },[props.Data])

    useEffect( ()=>{
        if (Data) {
            creatEditor(Data);
        }
        return ()=>{
            clearEditor()
        }
    },[Data])

    function clearEditor(){
        if (editor) {
            // editor.editor && editor.editor.destroy()
            editor.editor.editing && editor.editor.editing.destroy()
        }
    }
    function creatEditor(Data:string) {
        let now_timer = new Date();
        let now_timerStr = now_timer.getFullYear() +'-'+ (now_timer.getMonth() + 1) +'-'+ now_timer.getDate()
        editor = new Editor({
            id: 'articleManege-editor-area',
            token: window.localStorage.getItem('token'),
            signUrl: `//admintbt.taobaitao.com/admin/api/oss/sign?dir=brand/${now_timerStr}/`,
            Data: Data?Data:'<p></p>',
            onchange: ()=>{

            }
        })
        return editor
    }

    return(
        <div id={styles['articleManege-editor']}>
            <div id='articleManege-editor-area'></div>
        </div>
    )
})

export default ArticleManege_Editor;
