import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
import ArticleManege_Table from "./table";
import {
    Select,
    Input,
    Tabs,
    Table,
    Pagination,
    Button,
    Form,
    Upload,
    Radio,
    Row,
    Col,
    Checkbox,
    DatePicker,
    notification,
    List,
    Card,
} from 'antd';
const { Meta } =Card
import { getOSSSignature } from "@/services/uplaodImgOss";
import { PlusOutlined,BulbOutlined } from '@ant-design/icons';
import { articleEditorList } from "@/services/article";
import moment from 'moment'
import {addArticle, articleUpOrDown, handleArticle} from "@/services/articleManage";
import {copyTpwd, searchTao,channlUrl,createArticleBySearch} from "@/services/copyJd";
import {addBanner} from "@/services/bannerManage";
import {addBrand, handleBrand} from "@/services/brandManage";

const { Option } = Select;
const ArticleManege: React.FC = () => {
    const [search, setSearch] = useState<any>({
        text: '',
        hasCoupon:'',
        needFreeShipment:'',
        isOverseas:'',
        pageNum: 1,
        pageSize: 30,
    });
    const [tableTotal, setTableTotal] = useState(0);

    const [loading, setLoading] = useState<any>(null);
    const [nowItem, setNowItem] = useState<any>(null);
    const [brandData, setBrandList] =useState<any>(null);
    const [copyInput, setcopyInput] =useState<any>(null);


    let formRef = useRef<any>()

    useEffect(() => {
    }, [])

    useEffect(() => {
        if (!nowItem) {
        }

    }, [search, nowItem])

    function getSearch(search_c:any) {
        setLoading(true)
        searchTao(search_c).then((res:any) => {
            setLoading(false)
            let resData = res.datas;
            setBrandList(resData);
            setTableTotal(resData && resData.length > 0 ? resData[0].totalCount:0)
        })
    }




    function renderHeader() {

        function onChangeinput(e: any) {
            let search_c = { ...search };
            search_c.text = e;
            search_c.pageNum = 1;
            setSearch(search_c)
        }

        function onChangeinputCopy(e: any) {
            let items = { ...copyInput };
            items.text = e;
            setcopyInput(items)
        }
        function ondown(e:any){
            if(e==13){
                let search_c = { ...search };
                getSearch(search_c)
            }
        }
        function onChange_Form_Name(type: string, value: any) {
            let item = { ...nowItem };
            let sItem = { ...search };
            if(type=='activityType'){
                if(value.toString().indexOf('有券')!=-1){
                    sItem.hasCoupon = true;
                }else{
                    sItem.hasCoupon = '';
                }
                if(value.toString().indexOf('京东自营')!=-1){
                    sItem.isJdOwner = true;
                }else{
                    sItem.isJdOwner = '';
                }

            }


            item[type]=value
            sItem[type]=value
            setSearch(sItem)
            getSearch(sItem)
        }
        function copys(){

            copyTpwd(copyInput).then(res => {
                copyTpwd(copyInput).then(res => {
                    var input:any = document.getElementById('hiddenVal');
                    input.setAttribute('value', res.datas.fullUrl);
                    input.select();
                    if(res.datas.fullUrl && document.execCommand("copy")){
                        document.execCommand("copy");
                        notification.success({
                            message:'复制成功'
                        })
                    }else{
                        notification.error({
                            message: `复制失败,口令错误`,
                        });
                    }
                })
            })

        }
        function handleChange(type: string, value: number) {
            let search_c = { ...search };
            search_c[type] = value;
            setSearch(search_c)
        }
        return (
            <div className={styles.articleManageHeaderBox}>
                <div className={styles.articleManageHeader}>
                    <div>

                        <div className={styles.title}></div>
                        <Input value={search?search.name:''} onKeyDown={(e)=>{ondown(e.keyCode )}} onChange={(e) => { onChangeinput(e.target.value) }} placeholder="搜索" />
                    </div>
                    <Checkbox.Group onChange={(e) => { onChange_Form_Name('activityType', e) }} value={search?search.activityType:''}>
                        <Row>
                            <Checkbox value={'有券'}>有券</Checkbox>
                            <Checkbox value={'京东自营'}>京东自营</Checkbox>
                        </Row>
                    </Checkbox.Group>


                    {/*<div>*/}
                    {/*    <Button size={'middle'} type='primary' onClick={() => { setNowItem({}) }}>新增淘口令</Button>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <div className={styles.title}>根据id复制淘口令:</div>*/}
                    {/*    <Input value={search?search.name:''}  onChange={(e) => { onChangeinputCopy(e.target.value) }} placeholder="搜索" />*/}
                    {/*    <button className={styles.buttonCopy} onClick={()=>{copys()}}>复制淘口令</button>*/}
                    {/*</div>*/}
                    <input id='hiddenVal' className={styles.hiddenVal} type='text' />

                </div>

            </div>
        )
    }

    function renderNav() {
        return (
            <div className={styles.articleManageNav}>
                {renderTable()}
            </div>
        )
    }

    function renderTable() {

        function copy(e:any){
            createArticleBySearch(e).then(res => {
                sessionStorage.setItem('nowItem',JSON.stringify(res.datas))
                setNowItem(0)
                window['setNowItem']=setNowItem
                window.open('/#/childPage',"ziyemian",`width=1024,height=${window.screen.height},top=0,left=0,toobar=no,menubar=no,scrollbars=yes,location=no,status=no`)
                // var input:any = document.getElementById('hiddenVal');
                // input.setAttribute('value', res.datas.fullUrl);
                // input.select();
                // if(res.datas.fullUrl && document.execCommand("copy")){
                //     document.execCommand("copy");
                //     notification.success({
                //         message:'复制成功'
                //     })
                // }else{
                //     notification.error({
                //         message: `复制失败,口令错误`,
                //     });
                // }

            })
        }
        function onChangePagination(page: number, pageSize: number) {
            let search_c = { ...search };
            search_c.pageSize = pageSize;
            search_c.pageNum = page;
            setSearch(search_c)
            getSearch(search_c)

        }
        return (
            <div className={styles.table}>
                <List
                    grid={{
                        gutter: 16,
                        xs: 6,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                        xxl: 6,
                    }}
                    loading={loading?loading:false}
                    bordered={true}
                    dataSource={brandData?brandData:[]}
                    renderItem={(item:any) => (
                        <List.Item className={styles.spanTwoRow} >

                            <img
                                style={{width:'100%',height:'10vw',objectFit:'cover'}}
                                alt="logo"
                                onClick={()=>{
                                    window.open(`http:${item.originalUrl}`)
                                }}
                                src={item.headerImgUrl}
                            />
                            <span className={styles.titleName}>{item.title}</span>
                            <span className={styles.nickNma} style={{margin:0}}>{item.nick}</span>
                            <span className={styles.nickNma} style={{margin:0}}>{item.brandName}</span>
                            {Boolean(item.originalPrice!=item.price) && <div className={styles.itemPrice}>
                                <span>¥{(item.price)}</span>
                                <span>券{(item.originalPrice - item.price).toFixed(2)}元</span>
                            </div>}
                            {Boolean(item.originalPrice==item.price) && <div className={styles.itemPrice}>
                                <span>¥{item.price}</span>
                            </div>}
                            {(Boolean(item.couponEndTime)) && <span className={styles.nickName}>截止时间:{moment( item.couponEndTime).format('YYYY-MM-DD')}</span>}
                            <button onClick={()=>{copy(item)}}>创建优惠</button>
                        </List.Item>
                    )}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '2rem' }}>
                    <Pagination
                        showSizeChanger
                        showQuickJumper
                        showTotal={total => `总量： ${total} 条`}
                        pageSizeOptions={[10, 20, 50, 100, 1000]}
                        onChange={(page, pageSize) => { onChangePagination(page, pageSize) }}
                        pageSize={search.pageSize}
                        current={search.pageNum}
                        total={tableTotal === 10000 ? tableTotal - 30 : tableTotal}
                    />
                </div>

            </div>
        )
    }
    function renderHandle() {
        const { TextArea } = Input;
        function addItem() {
            let item = {...nowItem};

            channlUrl(item).then(res => {
                setNowItem(null)
            })

        }
        function beforeUpload(file: any) {
            getOSSSignature(file, '', file.name).then(res => {
                onChange_Form_Name('logo', res.fileURL)
            })
        }
        function onChange_Form_Name(type: string, value: any) {
            var regex = new RegExp("^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z_]){1,20}$");

            if(type=='lnitials'){
                if(!regex.test(value) && value!=''){
                    value='#'
                }
            }

            let item = { ...nowItem };
            item[type] = value;
            setNowItem(item)
        }
        return (
            <div className={styles.articleHandle}>
                <div className={styles.articleHandleHeader}>
                    <Button size={'middle'} type='primary' onClick={() => { setNowItem(null) }}>{'<<返回'}</Button>
                    <Button size={'middle'} type='primary' onClick={() => { addItem() }}>保存</Button>
                </div>
                <Row>
                    <Col span={16} offset={4}>
                        <Form
                            ref={formRef}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                            initialValues={nowItem}
                        >
                            <Form.Item label="口令" name="url">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.url}
                                        placeholder='请输入口令'
                                        onChange={(e) => { onChange_Form_Name('url', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label="开始时间（选填）" name="startTime">
                                <div className={styles.fromItem}>
                                    <DatePicker value={nowItem.startTime ? moment(nowItem.startTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('startTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />
                                </div>
                            </Form.Item>
                            <Form.Item label="过期时间（选填）" name="expirationTime">
                                <div className={styles.fromItem}>
                                    <DatePicker value={nowItem.expirationTime ? moment(nowItem.expirationTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('expirationTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />
                                </div>
                            </Form.Item>
                            <Form.Item label="渠道" name="channel">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('channel', e.target.value) }}  defaultValue={0} value={nowItem.channel}>
                                            <Radio value={'天猫'}>天猫</Radio>
                                            <Radio value={'京东'}>京东</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>


                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }


    return (
        <div className={styles.articleManege}>
            {
                Boolean(!nowItem) &&
                <React.Fragment>
                    {renderHeader()}
                    {renderNav()}
                </React.Fragment>
            }
            {
                Boolean(nowItem) &&
                <React.Fragment>
                    {renderHandle()}
                </React.Fragment>
            }


        </div>
    )
};

export default ArticleManege;
