import React, { useState } from "react";
import styles from "./index.module.less";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  PieChartOutlined,
} from "@ant-design/icons";
import { request } from "http";
import { renderRouteName, routerArr } from "@/router/router";

const { Header, Content, Footer, Sider } = Layout;

const logo_icon = new URL('../icon/img-logo.png', import.meta.url).href

const HomePage = (props:any) => {
  // 收缩侧边栏
  const [collapsed, setCollapsed] = useState<boolean>(false);
  return (
    <Layout style={{ minHeight: "100vh", width: "100%" }}>
      <Sider
        theme='dark'
        collapsible
        collapsed={collapsed}
        onCollapse={(isOpen) => setCollapsed(isOpen)}
      >
        <div className={styles.logo}><div><img src={logo_icon} alt="" />{collapsed?'':'淘白桃'}</div></div>
        <Menu
          theme="dark"
          defaultSelectedKeys={[`${useLocation().pathname}`]}
          mode="inline"
        >
          {
            routerArr().router.map( (item,index) => {
              return (
                <Menu.Item key={item.path} icon={<PieChartOutlined />}>
                  <Link to={item.path}>{item.name}</Link>
                </Menu.Item>
              )
            } )
          }
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header> <div className={styles.headerText}>{renderRouteName(props.history.location.pathname)}</div> </Header>
        <Content style={{ margin: "16px 16px" }}>{props.children}</Content>
        <Footer style={{ textAlign: "center" }}>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default HomePage;
