import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
import {
    addArticle,
    articleDel,
    articleGoTop,
    articleList,
    articleUpOrDown,
    getAllClassifys,
    getBrandList,
    getItemData,
    handleArticle,
    recommendLevel
} from "@/services/articleManage";
import {oneList, skuClassifyList, skuinfo, skuInfoSearch} from "@/services/oneManage";
import history from '../../router/history';

import ArticleManege_Table from "./table";
import {
    Select,
    Input,
    Tabs,
    Table,
    Pagination,
    Button,
    Form,
    Upload,
    Radio,
    Row,
    Col,
    Checkbox,
    DatePicker,
    AutoComplete,
    Popconfirm, notification,
    Image,Popover
} from 'antd';
import { getOSSSignature } from "@/services/uplaodImgOss";
import { PlusOutlined, BulbOutlined } from '@ant-design/icons';
import { articleEditorList } from "@/services/article";
import moment from 'moment'
import ArticleManege_Editor from "./editor";

const { Option } = Select;
const ArticleManege: React.FC = (props:any, context) => {

    const [search, setSearch] = useState<any>({
        brandId: null,
        pageNum: 1,
        pageSize: 20,
        status: 2,
        title: '',
        type: '全部',
        channelId:'',
        discount:0,
        page:1
    });
    const [tableTotal, setTableTotal] = useState(0);
    const [typeInfo, setTypeInfo] = useState<any>(null);
    const [brandInfo, setBrandInfo] = useState<any>(null);
    const [editorCodeArr, setEditorCodeArr] = useState<any>([])
    const [Data, setData] = useState<any>(null);
    const [nowItem, setNowItem] = useState<any>(props.children?JSON.parse(props.children):null);
    const [searchSku, setsearchSku] = useState<any>(null);
    const [Options, setOptions] = useState<any>(null);
    const [typeList, setTypeList] = useState<any>(null);
    const [twoTypeList, setTwoTypeList] = useState<any>(null);
    const [searchShop, setsearchShop] = useState<any>(null);



    let formRef = useRef<any>()
    const inputRef: any = useRef(null)

    useEffect(() => {
        getAllClassInfo();
        getArticleList()
        getBrandInfo();
        if(props.children){
            let childs=JSON.parse(props.children)
            if(childs.oneClassify){
                getSkuClass('')
                getSkuClass(childs.oneClassify?childs.oneClassify:'')
            }else{
                getSkuClass(childs.oneClassify?childs.oneClassify:'')
            }

        }
    }, [])

    useEffect(() => {
        if (!nowItem && search.searchs) {
            getArticleList()
        }
    }, [search, nowItem])

    function getAllClassInfo() {
        getAllClassifys().then(res => {
            let resData = res.datas ? res.datas : [];
            setTypeInfo(resData)
        })
    }

    function getBrandInfo() {
        getBrandList().then(res => {
            let resData = res.datas;
            resData.unshift({ id: null, name: '全部品牌', subName: '' })
            setBrandInfo(resData)
        })
    }

    function getArticleList(type?:boolean) {
        let v={...search}
        if(type){
            v.pageNum=1
        }
        articleList({ ...v }).then(res => {
            let resData = res.datas;
            let search_c = { ...search };
            search_c.searchs = 0;
            setSearch(search_c)
            setTableTotal(resData.total);
            setData(resData)
        })
    }

    function getSkuClass(oneClassify:any,recommend?:any){
        let param={
            classify:oneClassify,
        }
        skuClassifyList(param).then(res => {
            let resData = res.datas;

            if(oneClassify){
                setTwoTypeList(resData)
            }else{
                setTypeList(resData)
            }
        })
    }
    function renderHeader() {
        async function handleChange(type: string, value: any) {
            let search_c = { ...search };
            if((type=='recommend' || type=='status' || type=='autoUpdate' || type=='enterType') && (search_c[type]===value || (isNaN(value) && value!=='-1,1'))){
                value=''
            }
            search_c['searchs']=1
            search_c[type] = value;
            setSearch(search_c)
        }
        function onChangeinput(e: string) {
            let search_c = { ...search };
            search_c.title = e;
            setSearch(search_c)
        }

        return (
            <div className={styles.articleManageHeaderBox}>
                <div className={styles.articleManageHeader}>
                    <div>

                        <Input value={search ? search.title : ''} onChange={(e) => { onChangeinput(e.target.value) }} placeholder="搜索标题..." />
                        <Button onClick={()=>{
                            getArticleList(true)
                        }}>搜索</Button>
                    </div>
                    <div>
                        {
                            Boolean(brandInfo) &&
                            <Select showSearch optionFilterProp="children" value={search.brandId} style={{ width: 170 }} onChange={(value: number) => { handleChange('brandId', value) }}>
                                {
                                    brandInfo.map((item: any) => {
                                        return (
                                            <Option value={item.id} key={item.id}>{item.name + '  ' + item.subName}</Option>
                                        )
                                    })
                                }
                            </Select>
                        }
                    </div>
                    <div>
                        {
                            Boolean(brandInfo) &&
                            <Radio.Group value={search.recommend}  onChange={(e: any) => { handleChange('recommend', e.target.value) }} defaultValue="0" buttonStyle="solid">
                                <Radio.Button onClick={(e: any) => { handleChange('recommend', e) }} value={0}>普通</Radio.Button>
                                <Radio.Button onClick={(e: any) => { handleChange('recommend', e) }} value={1}>弱推</Radio.Button>
                                <Radio.Button onClick={(e: any) => { handleChange('recommend', e) }} value={2}>中推</Radio.Button>
                                <Radio.Button onClick={(e: any) => { handleChange('recommend', e) }} value={3}>强推</Radio.Button>

                            </Radio.Group>
                        }
                    </div>

                    <div>

                        {
                            Boolean(brandInfo) &&
                            <Radio.Group value={search.status}  onChange={(e: any) => { handleChange('status', e.target.value) }} defaultValue="2" buttonStyle="solid">
                                <Radio.Button onClick={(e: any) => { handleChange('status', e) }} value={2}>上架</Radio.Button>
                                <Radio.Button onClick={(e: any) => { handleChange('status', e) }} value={0}>下架</Radio.Button>
                                <Radio.Button onClick={(e: any) => { handleChange('status', e) }} value={'-1,1'}>待确认</Radio.Button>
                            </Radio.Group>
                        }
                        {/*{*/}
                        {/*    Boolean(brandInfo) &&*/}
                        {/*    <Select value={search.status} style={{ width: 100 }} onChange={(value: number) => { handleChange('status', value) }}>*/}
                        {/*        <Option value={null}>全部</Option>*/}
                        {/*        <Option value={-1}>草稿</Option>*/}
                        {/*        <Option value={0}>未上架</Option>*/}
                        {/*        <Option value={1}>上架</Option>*/}
                        {/*        <Option value={2}>过期</Option>*/}
                        {/*    </Select>*/}
                        {/*}*/}
                    </div>

                    <div>
                        {
                            Boolean(brandInfo) &&
                            <Radio.Group value={search.autoUpdate}  onChange={(e: any) => { handleChange('autoUpdate', e.target.value) }} defaultValue="null" buttonStyle="solid">
                                <Radio.Button onClick={(e: any) => { handleChange('autoUpdate', e) }} value={1}>自动更新</Radio.Button>
                                <Radio.Button onClick={(e: any) => { handleChange('autoUpdate', e) }} value={0}>手动更新</Radio.Button>
                            </Radio.Group>
                        }
                    </div>

                    {/*<div>*/}
                    {/*    {*/}
                    {/*        Boolean(brandInfo) &&*/}
                    {/*        <Radio.Group value={search.enterType}  onChange={(e: any) => { handleChange('enterType', e.target.value) }} defaultValue="null" buttonStyle="solid">*/}
                    {/*            <Radio.Button onClick={(e: any) => { handleChange('enterType', e) }} value={1}>编辑录入</Radio.Button>*/}
                    {/*            <Radio.Button onClick={(e: any) => { handleChange('enterType', e) }} value={2}>机器录入</Radio.Button>*/}
                    {/*        </Radio.Group>*/}
                    {/*    }*/}
                    {/*</div>*/}

                    {/*<div>*/}
                    {/*    {*/}
                    {/*        Boolean(brandInfo) &&*/}
                    {/*        <Radio.Group value={search.enterType}  onChange={(e: any) => { handleChange('enterType', e.target.value) }} defaultValue="null" buttonStyle="solid">*/}
                    {/*            <Radio.Button onClick={(e: any) => { handleChange('enterType', e) }} value={1}>编辑录入</Radio.Button>*/}
                    {/*            <Radio.Button onClick={(e: any) => { handleChange('enterType', e) }} value={2}>机器录入</Radio.Button>*/}
                    {/*        </Radio.Group>*/}
                    {/*    }*/}
                    {/*</div>*/}

                    <div>
                        {
                            <Select  optionFilterProp="children" value={search.channelId} style={{ width: 100 }} onChange={(value: number) => { handleChange('channelId', value) }}>
                                <Option value={''}>全部</Option>
                                <Option value={1}>京东</Option>
                                <Option value={2}>天猫</Option>
                                <Option value={3}>CDF</Option>
                                <Option value={4}>官微</Option>
                            </Select>
                        }
                    </div>

                    {/*<div>*/}
                    {/*    <div className={styles.title}>类型：</div>*/}
                    {/*    {*/}
                    {/*        Boolean(brandInfo) &&*/}
                    {/*        <Select value={search.articleType} style={{ width: 100 }} onChange={(value: number) => { handleChange('articleType', value) }}>*/}
                    {/*            <Option value={''}>无</Option>*/}
                    {/*            <Option value={1}>商品</Option>*/}
                    {/*            <Option value={2}>攻略</Option>*/}

                    {/*        </Select>*/}
                    {/*    }*/}
                    {/*</div>*/}


                </div>
                <div>
                    <Button size={'middle'} type='primary' onClick={() => {
                        getSkuClass('',1)
                        setNowItem(0)
                        window['setNowItem']=setNowItem
                        sessionStorage.setItem('nowItem',JSON.stringify({
                            itemType:0,
                            articleType:1,
                            content: '<p></p>',
                            channel: '天猫',
                            activityType:['满减'],
                            recommendLevel:0
                        }))
                        window.open('/#/childPage',"ziyemian","width=1240,height=1024,top=0,left=0,toobar=no,menubar=no,scrollbars=yes,location=no,status=no")
                    }}>新建优惠</Button>
                    {/*<Button size={'middle'} type='primary' style={{marginLeft:'.5vw'}} onClick={() => {*/}
                    {/*    getSkuClass('',0)*/}
                    {/*    setNowItem(0)*/}
                    {/*    window['setNowItem']=setNowItem*/}
                    {/*    sessionStorage.setItem('nowItem',JSON.stringify({*/}
                    {/*        oneClassify:'美食',*/}
                    {/*        itemType:2,*/}
                    {/*        articleType:1,*/}
                    {/*        content: '<p></p>',*/}
                    {/*        channel: '天猫',*/}
                    {/*        activityType:['满减'],*/}
                    {/*        recommendLevel:0*/}
                    {/*    }))*/}
                    {/*    window.open('/#/childPage',"ziyemian","width=1024,height=1024,top=0,left=0,toobar=no,menubar=no,scrollbars=yes,location=no,status=no")*/}
                    {/*}}>新建独立优惠</Button>*/}
                </div>
            </div>
        )
    }

    function renderNav() {
        const { TabPane } = Tabs
        function handleChange(value: any) {
            let search_c = { ...search };
            search_c.searchs = 1;
            search_c.type = value;
            setSearch(search_c)
        }
        return (
            <div className={styles.articleManageNav}>
                <Tabs style={{ width: '100%' }} activeKey={search.type} onChange={(e) => { handleChange(e) }} >
                    {
                        Boolean(typeInfo) &&
                        [...['全部', '我的'], ...typeInfo, '草稿'].map((item: any) => {
                            return (
                                <TabPane tab={item} key={item}>
                                    {renderTable()}
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
        )
    }

    function renderTable() {
        function goTop(id: string) {
            articleGoTop({ id: id }).then(res => {
                getArticleList()
            })
        }
        function goUpload(id: string) {
            articleUpOrDown({ id }).then(res => {
                getArticleList()
            })
        }
        function goDel(id: number) {
            articleDel( id).then(res => {
                getArticleList()
            })
        }
        function getItem(id: string) {
            getItemData(id).then(res => {
                let resData = res.datas;
                if (resData.activityType) {
                    resData.activityType = resData.activityType.split('#')
                }
                if (!resData.content) {
                    resData.content = '<p></p>'
                }
                sessionStorage.setItem('nowItem',JSON.stringify(resData))
                setNowItem(0)
                window['setNowItem']=setNowItem
                window.open('/#/childPage',"ziyemian",`width=1240,height=${window.screen.height},top=0,left=0,toobar=no,menubar=no,scrollbars=yes,location=no,status=no`)
            })
        }
        const columns: any = [
            {
                title: 'id',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                render: (title: string, record: any) => {

                    return (
                        <div className={styles.articleNitem}>
                            <Image width={75} src={record.headerImgUrl}/>
                            <div className={styles.articleNinfo}>
                                <span>{title}</span>
                                <span>{record.originalTitle}</span>
                                <span>
                                    <Image width={25} src={record.channelLogo}/>
                                    <span>{record.brandName}</span>
                                </span>
                            </div>
                            {/*{Boolean(record.top) && <span className={styles.topStyle}></span>}*/}

                        </div>
                    )
                }
            },
            {
                title: '原价',
                dataIndex: 'originalPrice',
                key: 'originalPrice',
                render:(text:string,record:any)=>{
                    return <a><span style={{fontSize:'.9rem'}}>{text?text:'--'}¥</span></a>
                }
            },
            {
                title: '现价',
                dataIndex: 'price',
                key: 'price',
                render:(text:string,record:any)=>{
                    return <a><span style={{textDecoration:'underline',fontSize:'.9rem',color:'#e4393c'}}>{text?text:'--'}¥</span></a>
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (status: string, record: any) => {
                    if (status == '-1') {
                        return (
                            <div><BulbOutlined style={{ color: '#e4393c' }} />待确认下架</div>
                        )
                    } else if (status == '0') {
                        return (
                            <div><BulbOutlined style={{ color: '#e4393c' }} />下架</div>
                        )
                    } else if (status == '1') {
                        return (
                            <div><BulbOutlined style={{ color: '#e4393c' }} />待确认上架</div>
                        )
                    } else if (status == '2') {
                        return (
                            <div><BulbOutlined style={{ color: 'rgb(82, 196, 26)' }} />上架</div>
                        )
                    } else if (status == '-99') {
                        return (
                            <div><BulbOutlined style={{ color: 'red' }} />草稿</div>
                        )
                    } else {
                        return null
                    }
                }
            },
            {
                title: '更新方式',
                dataIndex: 'autoUpdate',
                key: 'autoUpdate',
                render: (status: string, record: any) => {
                    if (status == '1') {
                        return (
                            <div><BulbOutlined style={{ color: 'rgb(82, 196, 26)' }} />自动</div>
                        )
                    } else if (status == '0') {
                        return (
                            <div><BulbOutlined style={{ color: '#e4393c' }} />手动</div>
                        )
                    } else {
                        return null
                    }
                }
            },
            {
                title: '推荐',
                dataIndex: 'recommendLevel',
                key: 'recommendLevel',
                render: (status: number, record: any) => {
                    let conn
                    if (status == 0) {
                        conn=<div><BulbOutlined style={{ color: '#999' }} />普通</div>
                    } else if (status == 1) {
                        conn=<div><BulbOutlined style={{ color: 'rgb(82, 196, 26)' }} />弱推</div>
                    }else if (status == 2) {
                        conn=<div><BulbOutlined style={{ color: 'rgb(82, 196, 26)' }} />中推</div>
                    }else if (status == 3) {
                        conn=<div><BulbOutlined style={{ color: 'rgb(82, 196, 26)' }} />强推</div>
                    }
                    function handleChangePush(e:number){
                        recommendLevel({
                            id:record.id,
                            recommendLevel:e
                        }).then(res => {
                        })
                    }
                    let cons=(<div className={styles.articleNpush}>
                        <span>更改推荐等级</span>
                        <div>
                            <Radio.Group   onChange={(e: any) => { handleChangePush(e.target.value) }} defaultValue={record.recommendLevel} buttonStyle="solid">
                                <Radio.Button value={0}>普通</Radio.Button>
                                <Radio.Button value={1}>弱推</Radio.Button>
                                <Radio.Button value={2}>中推</Radio.Button>
                                <Radio.Button value={3}>强推</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>)
                    return (<Popover content={cons} trigger="click">
                        {conn}
                    </Popover>)
                }
            },
            {
                title: '浏览数',
                dataIndex: 'readNum',
                key: 'readNum',
            },
            {
                title: '点赞数',
                dataIndex: 'likeNum',
                key: 'likeNum',
            },
            // {
            //     title: '分享数',
            //     dataIndex: 'shareNum',
            //     key: 'shareNum',
            // },

            {
                title: '推荐',
                dataIndex: 'enterType',
                key: 'enterType',
                render: (status: number, record: any) => {
                    if (status == 1) {
                        return (
                            <div style={{color:'#FF7F50'}}>机器人</div>
                        )
                    } else if (status === 0) {
                        return (
                            <div>{record.userName}</div>
                        )
                    }else{
                        return (
                            <div style={{color:record.userName=='机器人'?'#FF7F50':''}}>{record.userName}</div>
                        )
                    }
                }
            },
            {
                title: '编辑时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: (status: any, record: any) => {
                    return (<div>{moment(status).format('MM-DD HH:mm')}</div>)
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                width: 180,
                key: 'id',
                render: (id: any, record: any) => {
                    return (
                        <div className={styles.caozuoBtn}>
                            <a onClick={() => { getItem(id) }}>修改</a>
                            {/*<Popconfirm placement="top" title={record.top?'嗨确定取消置顶吗':'确定置顶吗'} onConfirm={()=>{goTop(id)}} okText="Yes" cancelText="No">*/}
                            {/*    <a href='#'>{record.top?'取消置顶':'置顶'}</a>*/}
                            {/*</Popconfirm>*/}

                            <a onClick={() => { goUpload(id) }}>{record.status == '2' ? '下架' : '上架'}</a>
                            <Popconfirm placement="top" title={'嗨确定删除吗'} onConfirm={()=>{goDel(id)}} okText="Yes" cancelText="No">
                                <a style={{color:'red'}} href='#'>删除</a>
                            </Popconfirm>

                        </div>
                    )
                }
            },
        ]
        function onChangePagination(page: number, pageSize: number) {
            let search_c = { ...search };
            search_c.pageSize = pageSize;
            search_c.searchs = 1;
            search_c.pageNum = page;
            setSearch(search_c)
        }
        return (
            <div className={styles.table}>
                <Table
                    pagination={false}
                    dataSource={Data ? Data.list : null}
                    columns={columns}
                ></Table>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '2rem' }}>
                    <Pagination
                        showSizeChanger
                        showQuickJumper
                        showTotal={total => `总量： ${total} 条`}
                        pageSizeOptions={[10, 20, 50, 100, 1000]}
                        onChange={(page, pageSize) => { onChangePagination(page, pageSize) }}
                        pageSize={search.pageSize}
                        current={search.pageNum}
                        total={tableTotal}
                    />
                </div>
            </div>
        )
    }

    function renderHandle() {
        const { TextArea } = Input;
        function addItem(num:string) {
            let item = { ...nowItem };
            item.activityType = nowItem.activityType ? nowItem.activityType.join('#') : ''
            item.content = inputRef.current.changeEditor();
            item.status = num;
            if(item.couponContent && !item.couponDesc){
                notification.error({
                    message: `请输入优惠标签`,
                });
                return
            }

            if (item.id) {
                handleArticle(item).then(res => {
                    window.opener.setNowItem(null);
                    window.close()
                })
            } else {
                addArticle(item).then(res => {
                    window.opener.setNowItem(null);
                    window.close()
                })
            }
        }
        function beforeUpload(file: any) {
            getOSSSignature(file, '', file.name).then(res => {
                onChange_Form_Name('headerImgUrl', res.fileURL)
            })
        }
        function onChange_Form_Name(type: string, value: any) {
            let item = { ...nowItem };
            item[type] = value;
            setNowItem(item)
        }
        function getNowItemCode(code: number) {
            let arr = [...editorCodeArr];
            arr.push(code)
            setEditorCodeArr(arr)
        }

        function handleChange(type: string, value: number) {
            if(type=='oneClassify'){
                getSkuClass(value)
            }
            let item = { ...nowItem };
            item[type] = value;
            setNowItem(item)
        }

        return (
            <div className={styles.articleHandle}>
                <div className={styles.articleHandleHeader}>
                    <div><Button size={'middle'} type='primary' onClick={() => {
                        window.opener.setNowItem(null);
                        window.close()
                    }}>{'<<返回'}</Button></div>
                    <div>
                        <Button size={'middle'} type='primary' onClick={() => { addItem('-99') }}>保存为草稿</Button>
                        <Button style={{marginLeft:'1rem'}} size={'middle'} type='primary' onClick={() => { addItem('2') }}>保存并上架</Button>
                    </div>
                </div>
                <Row>
                    <Col span={14}>
                        <Form
                            ref={formRef}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                            initialValues={nowItem}
                        >
                            <Form.Item label="标题" name="title">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.title}
                                        onChange={(e) => { onChange_Form_Name('title', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>


                            <Form.Item label="一级分类" name="oneClassify" style={{ width: '100%' }}>
                                {typeList && <Radio.Group className={styles.oneLevelNames}  onChange={(e) => {
                                    onChange_Form_Name('oneClassify', e.target.value)
                                }} value={nowItem.oneClassify}>
                                    {typeList.map((item: any) => (
                                        <Radio value={item.classify}>{item.classify}</Radio>))}
                                </Radio.Group>}
                            </Form.Item>


                            <Form.Item label="现价" name="price">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input value={nowItem.price} onChange={(e) => { onChange_Form_Name('price', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="原价" name="originalPrice">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input placeholder='选填' value={nowItem.originalPrice} onChange={(e) => { onChange_Form_Name('originalPrice', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>


                            <Form.Item label="头图" name="headerImgUrl">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={(file) => beforeUpload(file)}
                                        >
                                            {nowItem && nowItem.headerImgUrl ? <img src={nowItem.headerImgUrl} alt="avatar" style={{ width: '100px', height: '100px' }} /> :
                                                <PlusOutlined />}
                                        </Upload>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="所属品牌" name="brandAlias">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input value={nowItem.brandAlias} onChange={(e) => { onChange_Form_Name('brandAlias', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="优惠力度" name="recommendLevel">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('recommendLevel', e.target.value) }} value={nowItem.recommendLevel}>
                                            <Radio value={0}>普通</Radio>
                                            <Radio value={1}>弱推</Radio>
                                            <Radio value={2}>中推</Radio>
                                            <Radio value={3}>强推</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="活动渠道" name="channel">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('channel', e.target.value) }} value={nowItem.channel}>
                                            <Radio value={'天猫'}>天猫</Radio>
                                            <Radio value={'京东'}>京东</Radio>
                                            <Radio value={'cdf'}>cdf</Radio>
                                            <Radio value={'官微'}>官微</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="外部链接" name="outLink">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input value={nowItem.outLink} onChange={(e) => { onChange_Form_Name('outLink', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="活动类型" name="activityType">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Checkbox.Group onChange={(e) => { onChange_Form_Name('activityType', e) }} value={nowItem.activityType}>
                                            <Row>
                                                <Checkbox value={'满减'}>满减</Checkbox>
                                                <Checkbox value={'满赠'}>满赠</Checkbox>
                                                <Checkbox value={'新客'}>新客</Checkbox>
                                                <Checkbox value={'复购'}>复购</Checkbox>
                                            </Row>
                                            <Row>
                                                <Checkbox value={'试用'}>试用</Checkbox>
                                                <Checkbox value={'拼团'}>拼团</Checkbox>
                                                <Checkbox value={'积分'}>积分</Checkbox>
                                                <Checkbox value={'上新'}>上新</Checkbox>
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="优惠说明" name="couponContent">
                                <div className={styles.fromItem}>
                                    <div style={{ width:'100%' }} >
                                        <TextArea placeholder='选填' value={nowItem.couponContent} showCount maxLength={150} style={{ width:'100%', height: 120 }} onChange={(e)=>{onChange_Form_Name('couponContent', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="优惠标签" name="couponDesc">
                                <div className={styles.fromItem}>
                                    <div style={{ width:'100%' }} >
                                        <Input placeholder='选填' value={nowItem.couponDesc} onChange={(e) => { onChange_Form_Name('couponDesc', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="开始时间（选填）" name="startTime">
                                <div className={styles.fromItem}>
                                    <DatePicker value={nowItem.startTime ? moment(nowItem.startTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('startTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />
                                </div>
                            </Form.Item>
                            <Form.Item label="过期时间（选填）" name="expirationTime">
                                <div className={styles.fromItem}>
                                    <DatePicker value={nowItem.expirationTime ? moment(nowItem.expirationTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('expirationTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />
                                </div>
                            </Form.Item>
                            <Form.Item label="群分享文案" name="shareText">
                                <div className={styles.fromItem}>
                                    <div  style={{ width:'100vw' }}>
                                        <textarea style={{ width:'100%',height:'20vw' }} placeholder='选填' value={nowItem.shareText} onChange={(e) => { onChange_Form_Name('shareText', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="群分享时间" name="shareTime">
                                <div className={styles.fromItem}>
                                    <div >
                                        <DatePicker value={nowItem.shareTime ? moment(nowItem.shareTime) : null} showTime onChange={(value, dateString) => {
                                            onChange_Form_Name('shareTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                        }} />                                    </div>
                                </div>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={8}>
                        <ArticleManege_Editor ref={inputRef} Data={nowItem ? nowItem.content : null} getTbCode={(code: number) => { getNowItemCode(code) }} />
                    </Col>
                </Row>
            </div>
        )
    }

    function renderSkuHandle() {
        const { TextArea } = Input;
        function addItem(num:string) {
            let item = { ...nowItem };
            item.activityType = nowItem.activityType ? nowItem.activityType.join('#') : ''
            item.status = num;
            item.content = inputRef.current.changeEditor();
            if(item.couponContent && !item.couponDesc){
                notification.error({
                    message: `请输入优惠标签`,
                });
                return
            }
            if (item.id) {
                handleArticle(item).then(res => {
                    window.opener.setNowItem(null);
                    window.close()
                })
            } else {
                addArticle(item).then(res => {
                    window.opener.setNowItem(null);
                    window.close()
                })
            }
        }

        function onChange_Form_Name(type: string, value: any) {
            let item = { ...nowItem };
            if(type=='oneClassify' ||type=='twoClassify'){
                if(type=='oneClassify'){
                    item[type] = typeList[value].classify;
                    item[type+'Sid'] =typeList[value].sid;
                    getSkuClass(typeList[value].classify)
                }else{
                    item[type] = twoTypeList[value].classify;
                    item[type+'Sid'] =twoTypeList[value].sid;
                }
            }else{
                item[type] = value;
            }
            setNowItem(item)
        }
        function getNowItemCode(code: number) {
            let arr = [...editorCodeArr];
            arr.push(code)
            setEditorCodeArr(arr)
        }
        function ondown(e:any){
            if(e==13){
                let search = { ...searchShop };
                setsearchSku(search);
            }
        }
        const onChangeinput = (data: string) => {
            let item = { ...searchShop };
            item['word'] = data;
            setsearchShop(item)
        };
        const onChange = (data: string) => {
            let item = { ...nowItem };
            item['title'] = data;
            setNowItem(item)

        };
        const onSearch = (searchText: string) => {
            skuInfoSearch({word:searchText}).then(res => {
                let data=res.datas
                data=data.map((e:any)=>({...e,['value']:e.name+'-'+e.brandName}))
                setOptions(data)
            })
        };

        const onSelect = (data: string) => {
            let value='',title=''
            Options.map((e:any)=>{
                if(e.name+'-'+e.brandName+'-'+e.recordNo==data || e.name+'-'+e.brandName==data){
                    value=e.id
                    title=e.name
                }
            })
            skuinfo(value).then(res => {
                let item = { ...nowItem };
                item['content']=res.datas.content
                item['headerImgUrl']=res.datas.headImgUrl
                item['skuInfoId'] = value;
                item['title'] = title;
                item['oneClassify'] = res.datas.oneClassify?res.datas.oneClassify:''
                item['twoClassify'] = res.datas.twoClassify?res.datas.twoClassify:''
                setNowItem(item)
            })

        };
        function beforeUpload(file: any) {
            getOSSSignature(file, '', file.name).then(res => {
                onChange_Form_Name('headerImgUrl', res.fileURL)
            })
        }

        return (
            <div className={styles.articleHandle}>
                {/*<div className={styles.articleHandleHeader}>*/}
                {/*    <div><Button size={'middle'} type='primary' onClick={() => {*/}
                {/*        window.opener.setNowItem(null);*/}
                {/*        window.close()*/}
                {/*    }}>{'<<返回'}</Button></div>*/}
                {/*    <div>*/}
                {/*        <Button size={'middle'} type='primary' onClick={() => { addItem('-1') }}>保存为草稿</Button>*/}
                {/*        <Button style={{marginLeft:'1rem'}} size={'middle'} type='primary' onClick={() => { addItem('1') }}>保存并上架</Button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <Row>
                    <Col span={9}>
                        <Form
                            ref={formRef}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 19 }}
                            layout="horizontal"
                            initialValues={nowItem}
                        >

                            <Form.Item  name="title">
                                <div className={styles.articleRest}>
                                    <Input.Group compact>

                                        <AutoComplete
                                            onSelect={onSelect}
                                            onSearch={onSearch}
                                            onChange={onChange}
                                            style={{width:'100%'}}
                                            value={nowItem.title}
                                            placeholder="查找商品"
                                            options={Options}
                                        />
                                    </Input.Group>
                                    <Button size={'middle'} type='primary' onClick={() => {
                                        let item = { ...nowItem };
                                        let item2 = { ...nowItem };
                                        item2['content']='<p></p>'
                                        setNowItem(item2)
                                        skuinfo(item.skuInfoId).then(res => {
                                            item['content']=res.datas.content
                                            item['headerImgUrl']=res.datas.headImgUrl
                                            setNowItem(item)
                                        })
                                    }}>重置</Button>
                                    <Button style={{marginLeft:'1vw'}} size={'middle'} type='primary' onClick={() => {
                                        let item = { ...nowItem };
                                        item.skuInfoId=''
                                        setNowItem(item)

                                    }}>取消关联</Button>

                                </div>

                            </Form.Item>
                            <Form.Item label='商品标题' name="title">
                                <div>
                                    <TextArea placeholder='请输入' value={nowItem.title} showCount maxLength={150} style={{ width:'100%', height: 120 }}
                                              onChange={(e)=>{onChange_Form_Name('title', e.target.value) }} />

                                    {/*<Input*/}
                                    {/*    placeholder='请输入'*/}
                                    {/*    type="text"*/}
                                    {/*    value={nowItem.specs}*/}
                                    {/*    onChange={(e) => { onChange_Form_Name('specs', e.target.value) }}*/}
                                    {/*/>*/}
                                </div>
                            </Form.Item>
                            <Form.Item label='商品规格'  name="specs">
                                <div>
                                    <Input
                                        placeholder='请输入'
                                        type="text"
                                        value={nowItem.specs}
                                        onChange={(e) => { onChange_Form_Name('specs', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label='优惠后价'  name="price">
                                <div>
                                    <Input
                                        placeholder='请输入'
                                        type="text"
                                        value={nowItem.price}
                                        onChange={(e) => { onChange_Form_Name('price', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label='优惠前价'  name="originalPrice">
                                <div>
                                    <Input
                                        placeholder='请输入'
                                        type="text"
                                        value={nowItem.originalPrice}
                                        onChange={(e) => { onChange_Form_Name('originalPrice', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item label="所属品牌" name="brandAlias">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input value={nowItem.brandAlias} placeholder='请尽量输入英文名' onChange={(e) => { onChange_Form_Name('brandAlias', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="优惠力度" colon={true} name="recommendLevel">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('recommendLevel', e.target.value) }} value={nowItem.recommendLevel}>
                                            <Radio value={0}>普通</Radio>
                                            <Radio value={1}>弱推</Radio>
                                            <Radio value={2}>中推</Radio>
                                            <Radio value={3}>强推</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>


                            <Form.Item label="优惠渠道"   name="channel">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('channel', e.target.value) }} value={nowItem.channel}>
                                            <Radio value={'天猫'}>天猫</Radio>
                                            <Radio value={'京东'}>京东</Radio>
                                            <Radio value={'官微'}>官微</Radio>
                                            <Radio value={'CDF'}>CDF</Radio>
                                            <Radio value={'PDD'}>PDD</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item  label='优惠链接'   name="outLink">
                                <div>
                                    <Input
                                        placeholder='请输入'
                                        type="text"

                                        suffix={<Image width='20px' height='20px' src={nowItem.outLinkQrcode} style={{background:'#222'}}></Image>}
                                        value={nowItem.outLink}
                                        onChange={(e) => { onChange_Form_Name('outLink', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item label='优惠标签'  name="couponDesc">
                                <div className={styles.fromItem}>
                                    <div style={{ width:'100%' }} >
                                        <Input placeholder='请输入' value={nowItem.couponDesc} onChange={(e) => { onChange_Form_Name('couponDesc', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label='优惠说明'  name="couponContent">
                                <div className={styles.fromItem}>
                                    <div style={{ width:'100%' }} >
                                        <TextArea placeholder='请输入' value={nowItem.couponContent} showCount maxLength={150} style={{ width:'100%', height: 120 }} onChange={(e)=>{onChange_Form_Name('couponContent', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>






                            {/*<Form.Item label="活动类型"  labelCol={{span:24}}  name="activityType">*/}
                            {/*    <div className={styles.fromItem}>*/}
                            {/*        <div>*/}
                            {/*            <Checkbox.Group onChange={(e) => { onChange_Form_Name('activityType', e) }} value={nowItem.activityType}>*/}
                            {/*                <Row>*/}
                            {/*                    <Checkbox value={'满减'}>满减</Checkbox>*/}
                            {/*                    <Checkbox value={'满赠'}>满赠</Checkbox>*/}
                            {/*                    <Checkbox value={'新客'}>新客</Checkbox>*/}
                            {/*                    <Checkbox value={'复购'}>复购</Checkbox>*/}
                            {/*                </Row>*/}
                            {/*                <Row>*/}
                            {/*                    <Checkbox value={'试用'}>试用</Checkbox>*/}
                            {/*                    <Checkbox value={'拼团'}>拼团</Checkbox>*/}
                            {/*                    <Checkbox value={'积分'}>积分</Checkbox>*/}
                            {/*                    <Checkbox value={'上新'}>上新</Checkbox>*/}
                            {/*                </Row>*/}
                            {/*            </Checkbox.Group>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Form.Item>*/}
                            <Form.Item label='优惠开始' name="startTime">
                                <div className={styles.fromItem}>
                                    <DatePicker placeholder='（选填）' value={nowItem.startTime ? moment(nowItem.startTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('startTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />
                                </div>
                            </Form.Item>
                            <Form.Item label='优惠结束' name="expirationTime">
                                <div className={styles.fromItem}>
                                    <DatePicker  placeholder='（选填）' value={nowItem.expirationTime ? moment(nowItem.expirationTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('expirationTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />
                                </div>
                            </Form.Item>
                            <Form.Item label='提示：' name="statusExplain">
                                {(nowItem.status==1 || nowItem.status==-1) && <div className={styles.statusExplain}>
                                    {nowItem.statusExplain}
                                </div>}
                            </Form.Item>

                        </Form>
                    </Col>
                    <Col span={8}>
                        <ArticleManege_Editor ref={inputRef} Data={nowItem ? nowItem.content : null} getTbCode={(code: number) => { getNowItemCode(code) }} />
                    </Col>

                    <Col span={6}>
                        <Form.Item  name="headerImgUrl">
                            <div className={styles.fromItem}>
                                <div className={styles.fromItemRow}>
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        beforeUpload={(file) => beforeUpload(file)}
                                    >
                                        {nowItem && nowItem.headerImgUrl ? <img src={nowItem.headerImgUrl} alt="avatar" style={{ width: '100px', height: '100px' }} /> :
                                            <PlusOutlined />}
                                    </Upload>
                                    {nowItem && nowItem.headerImgUrl && <Image src={nowItem.headerImgUrl} alt="avatar" style={{ width: '100px', height: '100px' }} /> }

                                </div>
                            </div>
                        </Form.Item>

                        <Form.Item  name="oneClassify">
                            <Select showSearch optionFilterProp="children" placeholder='商品一级分类' defaultValue={nowItem.oneClassify} value={nowItem.oneClassify}  onChange={(value: number) => { onChange_Form_Name('oneClassify', value) }}>
                                {typeList && typeList.map((item: any,index:number) => {
                                        return (
                                            <Option value={index} key={item}>{item.classify }</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item  name="twoClassify" >
                            <Select showSearch optionFilterProp="children" placeholder='商品二级分类' defaultValue={nowItem.twoClassify} value={nowItem.twoClassify}   onChange={(value: number) => { onChange_Form_Name('twoClassify', value) }}>
                                {twoTypeList && twoTypeList.map((item: any,index:number) => {
                                    return (
                                        <Option value={index} key={item}>{item.classify }</Option>
                                    )
                                })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item  name="shareText">
                            <div className={styles.fromItem}>
                                <div style={{ width:'100vw' }} >
                                    <textarea style={{ width:'100%' ,height:'20vw'}}  placeholder='群分享文案' value={nowItem.shareText} onChange={(e) => { onChange_Form_Name('shareText', e.target.value) }} />
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item name="shareTime">
                            <div className={styles.fromItem}>
                                <div  >
                                    <DatePicker  placeholder='群分享时间' value={nowItem.shareTime ? moment(nowItem.shareTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('shareTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />                                    </div>
                            </div>
                        </Form.Item>
                        <div>

                            <Button size={'middle'} type='primary' onClick={() => { addItem('-99') }}>保存为草稿</Button>
                            <Button style={{marginLeft:'1rem'}} size={'middle'} type='primary' onClick={() => { addItem('-2') }}>忽略该优惠</Button>
                            <Button style={{marginLeft:'1rem'}} size={'middle'} type='primary' onClick={() => { addItem('2') }}>保存并上架</Button>
                            <Button style={{marginLeft:'1rem'}} size={'middle'} type='primary' onClick={() => {
                                window.opener.setNowItem(null);
                                window.close()
                            }}>关闭</Button>
                        </div>


                    </Col>

                </Row>
            </div>
        )
    }
    return (
        <div className={styles.articleManege}>
            {
                Boolean(!nowItem) &&
                <React.Fragment>
                    {renderHeader()}
                    {renderNav()}
                </React.Fragment>
            }
            {
                Boolean(nowItem) &&
                <React.Fragment>
                    {Boolean(nowItem.itemType) && renderHandle()}
                    {Boolean(!nowItem.itemType) && renderSkuHandle()}
                </React.Fragment>
            }
        </div>
    )
};
function reloads(){
    console.log('-----')
}
export default ArticleManege;
