import "antd/dist/antd.less";
import style from "./App.module.less";
import RouterPage from "./router";

/**
 * 入口
 * @constructor
 */
function App():JSX.Element {
  return (
    <div className={style.App}>
      <section className={style.AppSection}>
          <RouterPage />
      </section>
    </div>
  );
}

export default App;
