import {
  responseInterceptor,
  resultType, whyRequest, _getParam
} from "../utils/reuqest";

/**
 * 文章列表
 * @param params
 */
export interface oneType {
  brandId?: number,
  oneClassify?: number,
  pageNum?: number,
  pageSize?: number,
  twoClassify?: string,
  word?: string
}

/**
 * 单品列表
 * @param params
 */
export const oneList = async (params: oneType) => {
  const reslutData = await whyRequest.get(`/admin/api/skuInfo/list?${_getParam(params)}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}

/**
 * 单品搜索
 * @param params
 */
export const skuInfoSearch = async (params: oneType) => {
  const reslutData = await whyRequest.get(`/admin/api/skuInfo/search?${_getParam(params)}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}

/**
 * 删除单品
 * @param params
 */
export const skuInfoDel= async ( id: string ) => {
  const reslutData = await whyRequest.get(`/admin/api/skuInfo/del/${id}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}



/**
 * 分类列表
 * @param params
 */
export const skuClassifyList= async ( params: any ) => {
  const reslutData = await whyRequest.get(`/admin/api/skuInfo/skuClassifyList?${_getParam(params)}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}


/**
 * 搜索成分
 * @param params
 */
export const queryThirdData= async ( params: any ) => {
  const reslutData = await whyRequest.get(`/admin/api/skuInfo/queryThirdData?${_getParam(params)}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}

/**
 * 创建
 * @param params
 */
export interface AddskuType {
  alias: string,
  composition: string
  brandId: number
  content: string
  fitCrowd: string
  fitSkin: number
  headImgUrl: string
  name: string
  oneClassify: string
  recordName: string
  twoClassify: number
  id?: number
}

export const addsku = async (params: AddskuType) => {
  const reslutData = await whyRequest.post('/admin/api/skuInfo/add', { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}
export const updatesku = async (params: AddskuType) => {
  const reslutData = await whyRequest.post('/admin/api/skuInfo/update', { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}

export const skuinfo= async ( id: string ) => {
  const reslutData = await whyRequest.get(`/admin/api/skuInfo/info/${id}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}



