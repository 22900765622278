import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
import {
    addArticle,
    articleDel,
    articleGoTop,
    articleList,
    articleUpOrDown,
    getAllClassifys,
    getBrandList,
    getItemData,
    handleArticle,
    recommendLevel
} from "@/services/articleManage";
import {oneList, skuClassifyList, skuinfo, skuInfoSearch} from "@/services/oneManage";
import history from '../../router/history';

import ArticleManege_Table from "./table";
import {
    Select,
    Input,
    Tabs,
    Table,
    Pagination,
    Button,
    Form,
    Upload,
    Radio,
    Row,
    Col,
    Checkbox,
    DatePicker,
    AutoComplete,
    Popconfirm, notification,
    Image,Popover
} from 'antd';
const { Search } = Input;

import { getOSSSignature } from "@/services/uplaodImgOss";
import { PlusOutlined, BulbOutlined } from '@ant-design/icons';
import { articleEditorList } from "@/services/article";
import moment from 'moment'
import ArticleManege_Editor from "../articleManege/editor";

const { Option } = Select;

const ArticleManege: React.FC = (props:any, context) => {

    const [search, setSearch] = useState<any>({
        brandId: null,
        pageNum: 1,
        pageSize: 20,
        status: 0,
        title: '',
        type: '全部',
        channelId:'',
        page:2
    });
    const [tableTotal, setTableTotal] = useState(0);
    const [typeInfo, setTypeInfo] = useState<any>(null);
    const [brandInfo, setBrandInfo] = useState<any>(null);
    const [editorCodeArr, setEditorCodeArr] = useState<any>([])
    const [Data, setData] = useState<any>(null);
    const [nowItem, setNowItem] = useState<any>(props.children?JSON.parse(props.children):null);
    const [searchSku, setsearchSku] = useState<any>(null);
    const [Options, setOptions] = useState<any>(null);
    const [typeList, setTypeList] = useState<any>(null);
    const [twoTypeList, setTwoTypeList] = useState<any>(null);
    const [searchShop, setsearchShop] = useState<any>(null);
    const [ChildData, setChildData] = useState<any>(null);



    let formRef = useRef<any>()
    const inputRef: any = useRef(null)

    useEffect(() => {
        getAllClassInfo();
        getBrandInfo();
        getArticleList()

        if(props.children){
            getSkuClass('',JSON.parse(props.children).itemType?0:1)
        }
    }, [])

    useEffect(() => {
        if (!nowItem && search.searchs) {
            getArticleList()
        }

    }, [search, nowItem])
    function openArc(e:any){
        setTimeout(()=>{
            let data={...e}
            data['createTime']=moment(data.createTime).format('YYYY-MM-DD hh:mm:ss')
            data['startTime']=moment(data.startTime).format('YYYY-MM-DD hh:mm:ss')
            data['updateTime']=moment(data.updateTime).format('YYYY-MM-DD hh:mm:ss')
            data['activityType']=data.activityType?[data.activityType]:data.activityType
            data['status']=1
            sessionStorage.setItem('nowItem',JSON.stringify(data))
            setNowItem(0)
            window['setNowItem']=setNowItem
            window.open('/#/childPage',"ziyemian",`width=1024,height=${window.screen.height},top=0,left=0,toobar=no,menubar=no,scrollbars=yes,location=no,status=no`)
        },300)
    }
    function getAllClassInfo() {
        getAllClassifys().then(res => {
            let resData = res.datas ? res.datas : [];
            setTypeInfo(resData)
        })
    }

    function getBrandInfo() {
        getBrandList().then(res => {
            let resData = res.datas;
            resData.unshift({ id: null, name: '全部品牌', subName: '' })
            setBrandInfo(resData)
        })
    }

    function getArticleList(type?:boolean) {
        let v={...search}
        if(type){
            v.pageNum=1
        }
        articleList({ ...v }).then(res => {
            let resData = res.datas;
            let search_c = { ...search };
            search_c['searchs']=0
            setSearch(search_c)
            setTableTotal(resData.total);
            setData(resData)
        })
    }

    function getSkuClass(oneClassify:any,recommend?:any){
        let param={
            classify:oneClassify,
        }
        if(recommend|| recommend==0){
            param['recommend']=recommend
        }
        skuClassifyList(param).then(res => {
            let resData = res.datas;
            if(oneClassify){
                setTwoTypeList(resData)
            }else{
                setTypeList(resData)
            }
        })
    }
    function renderHeader() {
        function handleChange(type: string, value: any) {
            let search_c = { ...search };
            if((type=='recommend' || type=='status' || type=='autoUpdate' || type=='enterType') && (search_c[type]===value || isNaN(value))){
                value=''
            }

            search_c['searchs']=1
            search_c[type] = value;
            setSearch(search_c)
        }
        function onChangeinput(type:string,e: string) {
            let search_c = { ...search };
            search_c[type] = e;
            setSearch(search_c)
        }

        function onChangeinputSearch(type:string,e: string) {
            let search_c = { ...search };
            search_c[type] = e;
            search_c['searchs']=1

            setSearch(search_c)
        }
        return (
            <div className={styles.articleManageHeaderBox}>
                <div className={styles.articleManageHeader}>
                    <div>
                        <Input value={search ? search.title : ''} onChange={(e) => { onChangeinput('title',e.target.value) }} placeholder="搜索标题..." />
                        <Button onClick={()=>{
                            getArticleList(true)
                        }}>搜索</Button>
                    </div>
                    <div>
                        {
                            Boolean(brandInfo) &&
                            <Select showSearch optionFilterProp="children" value={search.brandId} style={{ width: 170 }} onChange={(value: number) => { handleChange('brandId', value) }}>
                                {
                                    brandInfo.map((item: any) => {
                                        return (
                                            <Option value={item.id} key={item.id}>{item.name + '  ' + item.subName}</Option>
                                        )
                                    })
                                }
                            </Select>
                        }
                    </div>

                    <div >
                            <div style={{wordBreak:'keep-all'}}>折扣大于:</div>
                            <Search  onSearch={(e:any) => { onChangeinputSearch('discountMin',e) }} placeholder="..." />
                            <div style={{wordBreak:'keep-all'}}>折扣小于:</div>
                            <Search  onSearch={(e:any) => { onChangeinputSearch('discountMax',e) }} placeholder="..." />
                    </div>
                    <div>
                        <div style={{wordBreak:'keep-all'}}>价格大于:</div>
                        <Search  onSearch={(e:any) => { onChangeinputSearch('priceMin',e) }} placeholder="..." />
                        <div style={{wordBreak:'keep-all'}}>价格小于:</div>
                        <Search  onSearch={(e:any) => { onChangeinputSearch('priceMax',e) }} placeholder="..." />
                    </div>
                    <div>
                        {
                            <Select  optionFilterProp="children" value={search.channelId} style={{ width: 100 }} onChange={(value: number) => { handleChange('channelId', value) }}>
                                <Option value={''}>全部</Option>
                                <Option value={1}>京东</Option>
                                <Option value={2}>天猫</Option>
                                <Option value={3}>CDF</Option>
                                <Option value={4}>官微</Option>
                            </Select>
                        }
                    </div>


                </div>

            </div>
        )
    }

    function renderNav() {
        const { TabPane } = Tabs
        function handleChange(value: any) {
            let search_c = { ...search };
            search_c.type = value;
            search_c.searchs = 1;
            setSearch(search_c)
        }
        return (
            <div className={styles.articleManageNav}>
                <Tabs style={{ width: '100%' }} activeKey={search.type} onChange={(e) => { handleChange(e) }} >
                    {
                        Boolean(typeInfo) &&
                        [...['全部'], ...typeInfo].map((item: any) => {
                            return (
                                <TabPane tab={item} key={item}>
                                    {renderTable()}
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
        )
    }

    function renderTable() {
        function goTop(id: string) {
            articleGoTop({ id: id }).then(res => {
                getArticleList()
            })
        }
        function goUpload(id: string) {
            articleUpOrDown({ id }).then(res => {
                getArticleList()
            })
        }
        function goDel(id: number) {
            articleDel( id).then(res => {
                getArticleList()
            })
        }
        function getItem(id: string) {
            getItemData(id).then(res => {
                let resData = res.datas;
                if (resData.activityType) {
                    resData.activityType = resData.activityType.split('#')
                }
                if (!resData.content) {
                    resData.content = '<p></p>'
                }

                resData.itemType=resData.skuInfoId?0:1
                sessionStorage.setItem('nowItem',JSON.stringify(resData))
                setNowItem(0)
                window['setNowItem']=setNowItem
                window.open('/#/childPage',"ziyemian",`width=1024,height=${window.screen.height},top=0,left=0,toobar=no,menubar=no,scrollbars=yes,location=no,status=no`)
                //setNowItem(resData)
            })
        }
        const columns: any = [
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                render: (title: string, record: any) => {

                    return (
                        <div className={styles.articleNitem}>
                            <Image width={75} src={record.headerImgUrl}/>
                            <div className={styles.articleNinfo}>
                                <span>{title}</span>
                                <span>{record.originalTitle}</span>
                                <span>
                                    <Image width={25} src={record.channelLogo}/>
                                    <span>{record.brandName}</span>
                                    <span>{record.shopTitle}</span>
                                </span>
                            </div>
                            {/*{Boolean(record.top) && <span className={styles.topStyle}></span>}*/}

                        </div>
                    )
                }
            },
            {
                title: '原价',
                dataIndex: 'originalPrice',
                key: 'originalPrice',
            },
            {
                title: '活动价',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: '券面额',
                dataIndex: 'couponDesc',
                key: 'couponDesc',
                render: (status: any, record: any) => {
                    return (<div>{record.originalPrice?record.originalPrice-record.price:record.originalPrice}</div>)
                }
            },
            {
                title: '折扣',
                dataIndex: 'discount',
                key: 'discount',
                render: (status: any, record: any) => {
                    return (<div>{status*100}%</div>)
                }
            },
            {
                title: '分类',
                dataIndex: 'oneClassify',
                key: 'oneClassify',
            },
            {
                title: '爬取时间',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (status: any, record: any) => {
                    return (<div>{moment(status).format('MM-DD HH:mm')}</div>)
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                width: 180,
                key: 'id',
                render: (id: any, record: any) => {
                    return (
                        <div className={styles.caozuoBtn}>
                            {(record.oneClassify=='美妆' || record.oneClassify=='护肤') && <a onClick={() => {
                                getItemData(record.id).then(res => {
                                    let resData = res.datas;
                                    resData['content']=resData.content?resData.content:'<p></p>'
                                    resData['activityType']=resData.activityType?[resData.activityType]:resData.activityType
                                    sessionStorage.setItem('nowItem',JSON.stringify(resData))
                                    setNowItem(0)
                                    window['setNowItem']=setNowItem
                                    window.open('/#/childPage',"ziyemian",`width=1240,height=${window.screen.height},top=0,left=0,toobar=no,menubar=no,scrollbars=yes,location=no,status=no`)
                                })

                            }}>关联单品</a>}

                            {(record.oneClassify=='美妆' || record.oneClassify=='护肤') && <a onClick={() => {
                                getSkuClass('',0)
                                let data= {}
                                data['headImgUrl']=record.headerImgUrl
                                data['name']=record.title
                                data['content']=record.content?record.content:'<p></p>'
                                data['brandId']=record.brandId
                                data['articleId']=record.id
                                sessionStorage.setItem('nowItem',JSON.stringify(data))
                                setNowItem(0)
                                window['setNowItem']=setNowItem
                                window['openArc']=openArc
                                window.open('/#/childPage?type=03As1',"ziyemian",`width=1240,height=${window.screen.height},top=0,left=0,toobar=no,menubar=no,scrollbars=yes,location=no,status=no`)
                            }}>创建新单品</a>}
                            {(record.oneClassify!='美妆' && record.oneClassify!='护肤') &&<a onClick={() => {
                                getItemData(record.id).then(res => {
                                    let resData = res.datas;
                                    resData['content']=resData.content?resData.content:'<p></p>'
                                    resData['activityType']=resData.activityType?[resData.activityType]:resData.activityType
                                    resData['status']=1
                                    sessionStorage.setItem('nowItem',JSON.stringify(resData))
                                    setNowItem(0)
                                    window['setNowItem']=setNowItem
                                    window.open('/#/childPage',"ziyemian",`width=1240,height=${window.screen.height},top=0,left=0,toobar=no,menubar=no,scrollbars=yes,location=no,status=no`)
                                })

                            }}>创建优惠</a>}
                            {record.originalUrl && <span style={{color:'#1890ff',cursor: 'pointer'}}  onClick={()=>{
                                if(record.originalUrl){
                                    if(record.originalUrl.indexOf('http')!=-1){
                                        window.open(record.originalUrl)
                                    }else{
                                        window.open('https://'+record.originalUrl)
                                    }
                                }
                            }}>查看详情</span>}
                        </div>
                    )
                }
            },
        ]
        function onChangePagination(page: number, pageSize: number) {
            let search_c = { ...search };
            search_c.pageSize = pageSize;
            search_c.searchs = 1;
            search_c.pageNum = page;
            setSearch(search_c)
        }
        return (
            <div className={styles.table}>
                <Table
                    pagination={false}
                    dataSource={Data ? Data.list : null}
                    columns={columns}
                ></Table>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '2rem' }}>
                    <Pagination
                        showSizeChanger
                        showQuickJumper
                        showTotal={total => `总量： ${total} 条`}
                        pageSizeOptions={[10, 20, 50, 100, 1000]}
                        onChange={(page, pageSize) => { onChangePagination(page, pageSize) }}
                        pageSize={search.pageSize}
                        current={search.pageNum}
                        total={tableTotal}
                    />
                </div>
            </div>
        )
    }

    function renderHandle() {
        const { TextArea } = Input;
        function addItem(num:string) {
            let item = { ...nowItem };
            item.activityType = nowItem.activityType ? nowItem.activityType.join('#') : ''
            item.content = inputRef.current.changeEditor();
            item.status = num;
            if(item.couponContent && !item.couponDesc){
                notification.error({
                    message: `请输入优惠标签`,
                });
                return
            }

            if (item.id) {
                handleArticle(item).then(res => {
                    window.opener.setNowItem(null);
                    window.close()
                })
            } else {
                addArticle(item).then(res => {
                    window.opener.setNowItem(null);
                    window.close()
                })
            }
        }
        function beforeUpload(file: any) {
            getOSSSignature(file, '', file.name).then(res => {
                onChange_Form_Name('headerImgUrl', res.fileURL)
            })
        }
        function onChange_Form_Name(type: string, value: any) {
            let item = { ...nowItem };
            item[type] = value;
            setNowItem(item)
        }
        function getNowItemCode(code: number) {
            let arr = [...editorCodeArr];
            arr.push(code)
            setEditorCodeArr(arr)
        }

        function handleChange(type: string, value: number) {
            if(type=='oneClassify'){
                getSkuClass(value)
            }
            let item = { ...nowItem };
            item[type] = value;
            setNowItem(item)
        }

        return (
            <div className={styles.articleHandle}>
                <div className={styles.articleHandleHeader}>
                    <div><Button size={'middle'} type='primary' onClick={() => {
                        window.opener.setNowItem(null);
                        window.close()
                    }}>{'<<返回'}</Button></div>
                    <div>
                        <Button size={'middle'} type='primary' onClick={() => { addItem('-1') }}>保存为草稿</Button>
                        <Button style={{marginLeft:'1rem'}} size={'middle'} type='primary' onClick={() => { addItem('2') }}>保存并上架</Button>
                    </div>
                </div>
                <Row>
                    <Col span={14}>
                        <Form
                            ref={formRef}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                            initialValues={nowItem}
                        >
                            <Form.Item label="标题" name="title">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.title}
                                        onChange={(e) => { onChange_Form_Name('title', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>


                            <Form.Item label="一级分类" name="oneClassify">
                                {typeList && <Radio.Group onChange={(e) => {
                                    onChange_Form_Name('oneClassify', e.target.value)
                                }} value={nowItem.oneClassify}>
                                    {typeList.map((item: any) => (
                                        <Radio value={item.classify}>{item.classify}</Radio>))}
                                </Radio.Group>}
                                {/*{*/}
                                {/*    Boolean(typeList) &&*/}
                                {/*    <Select showSearch optionFilterProp="children" value={nowItem.oneClassify}  onChange={(value: number) => { handleChange('oneClassify', value) }}>*/}
                                {/*        {*/}
                                {/*            typeList.map((item: any) => {*/}
                                {/*                return (*/}
                                {/*                    <Option value={item.classify} key={item.id}>{item.classify}</Option>*/}
                                {/*                )*/}
                                {/*            })*/}
                                {/*        }*/}
                                {/*    </Select>*/}
                                {/*}*/}
                            </Form.Item>


                            <Form.Item label="现价" name="price">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input value={nowItem.price} onChange={(e) => { onChange_Form_Name('price', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="原价" name="originalPrice">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input placeholder='选填' value={nowItem.originalPrice} onChange={(e) => { onChange_Form_Name('originalPrice', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>


                            <Form.Item label="头图" name="headerImgUrl">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={(file) => beforeUpload(file)}
                                        >
                                            {nowItem && nowItem.headerImgUrl ? <img src={nowItem.headerImgUrl} alt="avatar" style={{ width: '100px', height: '100px' }} /> :
                                                <PlusOutlined />}
                                        </Upload>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="所属品牌" name="brandAlias">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input value={nowItem.brandAlias} onChange={(e) => { onChange_Form_Name('brandAlias', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="优惠力度" name="recommendLevel">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('recommendLevel', e.target.value) }} value={nowItem.recommendLevel}>
                                            <Radio value={0}>普通</Radio>
                                            <Radio value={1}>弱推</Radio>
                                            <Radio value={2}>中推</Radio>
                                            <Radio value={3}>强推</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="活动渠道" name="channel">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('channel', e.target.value) }} value={nowItem.channel}>
                                            <Radio value={'天猫'}>天猫</Radio>
                                            <Radio value={'京东'}>京东</Radio>
                                            <Radio value={'cdf'}>cdf</Radio>
                                            <Radio value={'官微'}>官微</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="外部链接" name="outLink">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input value={nowItem.outLink} onChange={(e) => { onChange_Form_Name('outLink', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="活动类型" name="activityType">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Checkbox.Group onChange={(e) => { onChange_Form_Name('activityType', e) }} value={nowItem.activityType}>
                                            <Row>
                                                <Checkbox value={'满减'}>满减</Checkbox>
                                                <Checkbox value={'满赠'}>满赠</Checkbox>
                                                <Checkbox value={'新客'}>新客</Checkbox>
                                                <Checkbox value={'复购'}>复购</Checkbox>
                                            </Row>
                                            <Row>
                                                <Checkbox value={'试用'}>试用</Checkbox>
                                                <Checkbox value={'拼团'}>拼团</Checkbox>
                                                <Checkbox value={'积分'}>积分</Checkbox>
                                                <Checkbox value={'上新'}>上新</Checkbox>
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="优惠说明" name="couponContent">
                                <div className={styles.fromItem}>
                                    <div style={{ width:'100%' }} >
                                        <TextArea placeholder='选填' value={nowItem.couponContent} showCount maxLength={150} style={{ width:'100%', height: 120 }} onChange={(e)=>{onChange_Form_Name('couponContent', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="优惠标签" name="couponDesc">
                                <div className={styles.fromItem}>
                                    <div style={{ width:'100%' }} >
                                        <Input placeholder='选填' value={nowItem.couponDesc} onChange={(e) => { onChange_Form_Name('couponDesc', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="开始时间（选填）" name="startTime">
                                <div className={styles.fromItem}>
                                    <DatePicker value={nowItem.startTime ? moment(nowItem.startTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('startTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />
                                </div>
                            </Form.Item>
                            <Form.Item label="过期时间（选填）" name="expirationTime">
                                <div className={styles.fromItem}>
                                    <DatePicker value={nowItem.expirationTime ? moment(nowItem.expirationTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('expirationTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />
                                </div>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={8}>
                        <ArticleManege_Editor ref={inputRef} Data={nowItem ? nowItem.content : null} getTbCode={(code: number) => { getNowItemCode(code) }} />
                    </Col>
                </Row>
            </div>
        )
    }

    function renderSkuHandle() {
        const { TextArea } = Input;
        function addItem(num:string) {
            let item = { ...nowItem };
            item.activityType = nowItem.activityType ? nowItem.activityType.join('#') : ''
            item.status = num;
            if(item.couponContent && !item.couponDesc){
                notification.error({
                    message: `请输入优惠标签`,
                });
                return
            }

            if (item.id) {
                handleArticle(item).then(res => {
                    window.opener.setNowItem(null);
                    window.close()
                })
            } else {
                addArticle(item).then(res => {
                    window.opener.setNowItem(null);
                    window.close()
                })
            }
        }

        function onChange_Form_Name(type: string, value: any) {
            let item = { ...nowItem };
            item[type] = value;
            setNowItem(item)
        }
        function getNowItemCode(code: number) {
            let arr = [...editorCodeArr];
            arr.push(code)
            setEditorCodeArr(arr)
        }
        function ondown(e:any){
            if(e==13){
                let search = { ...searchShop };
                setsearchSku(search);
            }
        }
        const onChangeinput = (data: string) => {
            let item = { ...searchShop };
            item['word'] = data;
            setsearchShop(item)
        };
        const onChange = (data: string) => {
            let item = { ...nowItem };
            item['title'] = data;
            setNowItem(item)

        };
        const onSearch = (searchText: string) => {
            skuInfoSearch({word:searchText}).then(res => {
                let data=res.datas
                data=data.map((e:any)=>({...e,['value']:e.name+'-'+e.brandName}))
                setOptions(data)
            })
        };

        const onSelect = (data: string) => {
            let value='',title=''
            Options.map((e:any)=>{
                if(e.name+'-'+e.brandName+'-'+e.recordNo==data || e.name+'-'+e.brandName==data){
                    value=e.id
                    title=e.name
                }
            })
            skuinfo(value).then(res => {
                let item = { ...nowItem };
                item['content']=res.datas.content
                item['headerImgUrl']=res.datas.headImgUrl
                item['skuInfoId'] = value;
                item['title'] = title;
                setNowItem(item)
            })

        };
        function beforeUpload(file: any) {
            getOSSSignature(file, '', file.name).then(res => {
                onChange_Form_Name('headerImgUrl', res.fileURL)
            })
        }

        return (
            <div className={styles.articleHandle}>
                <div className={styles.articleHandleHeader}>
                    <div><Button size={'middle'} type='primary' onClick={() => {
                        window.opener.setNowItem(null);
                        window.close()
                    }}>{'<<返回'}</Button></div>
                    <div>
                        <Button size={'middle'} type='primary' onClick={() => { addItem('-1') }}>保存为草稿</Button>
                        <Button style={{marginLeft:'1rem'}} size={'middle'} type='primary' onClick={() => { addItem('2') }}>保存并上架</Button>
                    </div>
                </div>
                <Row>
                    <Col span={14}>
                        <Form
                            ref={formRef}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                            initialValues={nowItem}
                        >

                            <Form.Item  name="price">
                                <div>
                                    <Input
                                        placeholder='请输入商品价格'
                                        type="text"
                                        value={nowItem.price}
                                        onChange={(e) => { onChange_Form_Name('price', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item  name="originalPrice">
                                <div>
                                    <Input
                                        placeholder='请输入商品原价（选填）'
                                        type="text"
                                        value={nowItem.originalPrice}
                                        onChange={(e) => { onChange_Form_Name('originalPrice', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item  name="specs">
                                <div>
                                    <Input
                                        placeholder='请输入商品规格（选填）'
                                        type="text"
                                        value={nowItem.specs}
                                        onChange={(e) => { onChange_Form_Name('specs', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item  name="couponDesc">
                                <div className={styles.fromItem}>
                                    <div style={{ width:'100%' }} >
                                        <Input placeholder='请输入优惠亮点（选填）' value={nowItem.couponDesc} onChange={(e) => { onChange_Form_Name('couponDesc', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item  name="couponContent">
                                <div className={styles.fromItem}>
                                    <div style={{ width:'100%' }} >
                                        <TextArea placeholder='请输入优惠说明（选填）' value={nowItem.couponContent} showCount maxLength={150} style={{ width:'100%', height: 120 }} onChange={(e)=>{onChange_Form_Name('couponContent', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>


                            <Form.Item label="优惠力度" labelCol={{span:24}} colon={true} name="recommendLevel">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('recommendLevel', e.target.value) }} value={nowItem.recommendLevel}>
                                            <Radio value={0}>普通</Radio>
                                            <Radio value={1}>弱推</Radio>
                                            <Radio value={2}>中推</Radio>
                                            <Radio value={3}>强推</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="所属渠道"  labelCol={{span:24}}  name="channel">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('channel', e.target.value) }} value={nowItem.channel}>
                                            <Radio value={'天猫'}>天猫</Radio>
                                            <Radio value={'京东'}>京东</Radio>
                                            <Radio value={'官微'}>官微</Radio>
                                            <Radio value={'CDF'}>CDF</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item    name="outLink">
                                <div>
                                    <Input
                                        placeholder='请输入链接\淘口令'
                                        type="text"
                                        value={nowItem.outLink}
                                        onChange={(e) => { onChange_Form_Name('outLink', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>


                            <Form.Item label="活动类型"  labelCol={{span:24}}  name="activityType">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Checkbox.Group onChange={(e) => { onChange_Form_Name('activityType', e) }} value={nowItem.activityType}>
                                            <Row>
                                                <Checkbox value={'满减'}>满减</Checkbox>
                                                <Checkbox value={'满赠'}>满赠</Checkbox>
                                                <Checkbox value={'新客'}>新客</Checkbox>
                                                <Checkbox value={'复购'}>复购</Checkbox>
                                            </Row>
                                            <Row>
                                                <Checkbox value={'试用'}>试用</Checkbox>
                                                <Checkbox value={'拼团'}>拼团</Checkbox>
                                                <Checkbox value={'积分'}>积分</Checkbox>
                                                <Checkbox value={'上新'}>上新</Checkbox>
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item name="startTime">
                                <div className={styles.fromItem}>
                                    <DatePicker placeholder='优惠开始时间（选填）' value={nowItem.startTime ? moment(nowItem.startTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('startTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />
                                </div>
                            </Form.Item>
                            <Form.Item name="expirationTime">
                                <div className={styles.fromItem}>
                                    <DatePicker  placeholder='优惠自动下架时间（选填）' value={nowItem.expirationTime ? moment(nowItem.expirationTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('expirationTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />
                                </div>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={8}>
                        <Form.Item  name="title">
                            <div className={styles.articleRest}>
                                <Input.Group compact>

                                    <AutoComplete
                                        onSelect={onSelect}
                                        onSearch={onSearch}
                                        onChange={onChange}
                                        style={{width:'100%'}}
                                        value={nowItem.title}
                                        placeholder="查找商品"
                                        options={Options}
                                    />
                                </Input.Group>
                                <Button size={'middle'} type='primary' onClick={() => {
                                    let item = { ...nowItem };
                                    let item2 = { ...nowItem };
                                    item2['content']='<p></p>'
                                    setNowItem(item2)
                                    skuinfo(item.skuInfoId).then(res => {
                                        item['content']=res.datas.content
                                        item['headerImgUrl']=res.datas.headImgUrl
                                        setNowItem(item)
                                    })
                                }}>重置</Button>

                            </div>

                        </Form.Item>

                        <Form.Item  name="headerImgUrl">
                            <div className={styles.fromItem}>
                                <div>
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        beforeUpload={(file) => beforeUpload(file)}
                                    >
                                        {nowItem && nowItem.headerImgUrl ? <img src={nowItem.headerImgUrl} alt="avatar" style={{ width: '100px', height: '100px' }} /> :
                                            <PlusOutlined />}
                                    </Upload>
                                </div>
                            </div>
                        </Form.Item>
                        <ArticleManege_Editor ref={inputRef} Data={nowItem ? nowItem.content : null} getTbCode={(code: number) => { getNowItemCode(code) }} />
                    </Col>

                </Row>
            </div>
        )
    }
    return (
        <div className={styles.articleManege}>
            {
                Boolean(!nowItem) &&
                <React.Fragment>
                    {renderHeader()}
                    {renderNav()}
                </React.Fragment>
            }
            {
                Boolean(nowItem) &&
                <React.Fragment>
                    {Boolean(nowItem.itemType) && renderHandle()}
                    {Boolean(!nowItem.itemType) && renderSkuHandle()}
                </React.Fragment>
            }
        </div>
    )
};
function reloads(){
    console.log('-----')
}
export default ArticleManege;
