import whyRequestCORS, { whyRequest, _getParam } from "../utils/reuqest";


export async function getOSSSignature(params: any, url: any, fileName: any) {
    let date = (new Date).getTime();
    let now_timer = new Date();
    let now_timerStr = now_timer.getFullYear() +'-'+ (now_timer.getMonth() + 1) +'-'+ now_timer.getDate() +'/'
    let sign:any = await getSign({dir:`article/${now_timerStr}`});
    const pathName = /\.[^\.]+$/.exec(params.name);
    const localName = fileName?date+fileName:`${random_string(12)}-zlimg${pathName}`;
    const signature = sign.datas;
    let localImgname=new Date().getTime()+Math.floor(Math.random()*100000)

    let fileURL = `${signature.host}/${url?url:signature.dir}${localImgname}.${fileName.split('.')[1]}`;
    fileURL = encodeURI(fileURL);
    const uploadParams = {
        name: localImgname,
        key: `${signature.dir}${localImgname}.${fileName.split('.')[1]}`,
        policy: signature.policy,
        OSSAccessKeyId: signature.accessid,
        success_action_status: '200',
        signature: signature.signature,
    };

    await Upload(signature.host, uploadParams, localImgname, params);
    const result = {
        filename: params.name,
        fileURL,
    };

    return result;
}

export const getSign = (params:{dir:string}) => {
    return whyRequest.get(`/admin/api/oss/sign?${_getParam(params)}`);
}

export const Upload = (baseURL: any, params: any, filename: any, file: any) => new Promise((resolve, reject) => {
    if (typeof XMLHttpRequest === 'undefined') {
        return;
    }
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    if (params) {
        Object.keys(params).map(key => {
            formData.append(key, params[key]);
            return key;
        });
    }

    formData.append('file', file);
    xhr.onload = () => {
    };

    xhr.open('post', baseURL, true);
    xhr.responseType = 'json';
    xhr.onreadystatechange = () => {

        if (xhr.readyState === 4) {

            if (xhr.status === 200) {

                resolve(xhr.response);
            } else {
                resolve('');
            }
        }
    };
    const headers = params.headers || {};
    for (const h in headers) {
        if (headers.hasOwnProperty(h) && headers[h] !== null) {
            xhr.setRequestHeader(h, headers[h]);
        }
    }
    xhr.send(formData);
});

export const random_string = (len: any) => {
    len = len || 32;
    const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    const maxPos = chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}
