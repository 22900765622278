import DataBoard from "@/pages/dataBoard";
import ArticleManege from "@/pages/articleManege";
import NptimeManege from "@/pages/nptimeManege";

import BrandManege from "@/pages/brandManege";
import LunManege from "@/pages/lunManege";
import CopyTao from "@/pages/copyTao";
import OneManege from "@/pages/oneManege";
import TodayTao from "@/pages/todayTao";
import SuperTao from "@/pages/SuperTao";
import WhereManege from "@/pages/whereManege";
import ClassManege from "@/pages/classManege";
import ShopIdManege from "@/pages/shopIdManege";
import CopyJd from "@/pages/copyJd";





export const routerArr = () => {
    return {
        defaultRouter: '/articleManege', //默认显示的路由
        router:[

            {
                name:'优惠管理',
                path:'/articleManege',
                componment: ArticleManege
            },
            {
                name:'任务管理',
                path:'/whereManege',
                componment: WhereManege
            },
            {
                name:'待上架管理',
                path:'/NptimeManege',
                componment: NptimeManege
            },
            {
                name:'品牌管理',
                path:'/brandManege',
                componment: BrandManege
            },
            {
                name:'单品管理',
                path:'/oneManege',
                componment: OneManege
            },
            {
                name:'团长管理',
                path:'/classManege',
                componment: ClassManege
            },
            {
                name:'店铺关键词管理',
                path:'/shopIdManege',
                componment: ShopIdManege
            },
            {
                name:'每日白桃',
                path:'/TodayTao',
                componment: TodayTao
            },
            {
                name:'天猫优惠搜索',
                path:'/CopyTao',
                componment: CopyTao
            },
            {
                name:'京东优惠搜索',
                path:'/CopyJd',
                componment: CopyJd
            },
            {
                name:'活动专辑',
                path:'/SuperTao',
                componment: SuperTao
            }
        ]
    }
}

export const renderRouteName = (path:string) => {
    let arr = routerArr().router;
    let name = '';
    arr.forEach((item,index) => {
        if (item.path == path) {
            name = item.name;
        }
    })
    return name
}