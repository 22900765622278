import {
  responseInterceptor,
  resultType, whyRequest, _getParam
} from "../utils/reuqest";
import {AddArticleType} from "@/services/articleManage";

/**
 * 品牌列表
 * @param params
 */
export interface brandManage {
  lnitials: number,	//页码
  name: number, //	页数
}

export const brandList = async (params: brandManage) => {
  const reslutData = await whyRequest.get(`/admin/api/brand/list?${_getParam(params)}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}



//首字母列表
export const lnitialsList = async () => {
  const reslutData = await whyRequest.get(`/admin/api/brand/lnitialsList`);
  return responseInterceptor(reslutData) as Promise<resultType>
}



/**
 * 创建
 * @param params
 */
export interface AddBrandType {
  classify: string
  level: number
  country: string
  description: string
  lnitials: string
  logo: string
  name: string
  nationality: string
  parentCompany: string
  subName: string
  id?: number
}

export const addBrand = async (params: AddBrandType) => {
  const reslutData = await whyRequest.post('/admin/api/brand/add', { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}

export const handleBrand = async (params: AddArticleType) => {
  const reslutData = await whyRequest.post('/admin/api/brand/update', { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}

export const brandDelete = async (params: { id: string }) => {
  const reslutData = await whyRequest.get(`/admin/api/brand/delete?id=${params.id}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}

