import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
import {addBrand, brandDelete, brandList, handleBrand, lnitialsList} from "@/services/brandManage";
import ArticleManege_Table from "./table";
import {
    Select,
    Input,
    Tabs,
    Table,
    Pagination,
    Button,
    Form,
    Upload,
    Radio,
    Row,
    Col,
    Checkbox,
    DatePicker,
    notification,
    Popconfirm
} from 'antd';
import { getOSSSignature } from "@/services/uplaodImgOss";
import { PlusOutlined,BulbOutlined } from '@ant-design/icons';
import { articleEditorList } from "@/services/article";
import moment from 'moment'
import {addArticle, articleUpOrDown, handleArticle} from "@/services/articleManage";

const { Option } = Select;
const ArticleManege: React.FC = () => {
    const [search, setSearch] = useState<any>({
        lnitials: 'A',
        name: '',
    });
    const [tableTotal, setTableTotal] = useState(0);
    const [typeInfo, setTypeInfo] = useState<any>(null);
    const [brandInfo, setBrandInfo] = useState<any>(null);
    const [Data, setData] = useState<any>(null);
    const [nowItem, setNowItem] = useState<any>(null);
    const [brandData, setBrandList] =useState<any>(null);
    const [lnitialsData, setlnitialsList] =useState<any>(null);

    let formRef = useRef<any>()

    useEffect(() => {
        getBrandList();
        getlnitialsList();
    }, [])

    useEffect(() => {
        if (!nowItem) {
            getBrandList()
        }
    }, [search, nowItem])

    function getBrandList() {
        brandList({...search}).then((res:any) => {
            let resData = res.datas;
            resData.map(()=>{

            })
            setBrandList(resData);
        })
    }

    function getlnitialsList() {
        lnitialsList().then((res:any) => {
            let resData = res.datas;
            resData=resData.map((e:any)=>(e.toUpperCase()))
            setlnitialsList(resData);
        })
    }



    function renderHeader() {
        function handleChange(type: string, value: number) {
            let search_c = { ...search };
            search_c[type] = value;
            setSearch(search_c)
        }
        function onChangeinput(e: any) {
            let search_c = { ...search };
            search_c.name = e;
            setSearch(search_c)
        }
        return (
            <div className={styles.articleManageHeaderBox}>
                <div className={styles.articleManageHeader}>
                    <div>
                        <div className={styles.title}></div>
                        <Input value={search?search.name:''} onChange={(e) => { onChangeinput(e.target.value) }} placeholder="搜索品牌" />
                    </div>
                </div>
                <Button size={'middle'} type='primary' onClick={() => { setNowItem({}) }}>新建品牌</Button>
            </div>
        )
    }

    function renderNav() {

        const { TabPane } = Tabs
        function handleChange(value: any) {
            let search_c = { ...search };
            search_c.lnitials = value;
            setSearch(search_c)
        }

        return (
            <div className={styles.articleManageNav}>
                <Tabs style={{ width: '100%' }} onTabClick={(e)=>{
                    let search_c = { ...search };
                    if(search_c.lnitials==e){
                        search_c.lnitials = '';
                        setSearch(search_c)
                    }else if(!search_c.lnitials){
                        search_c.lnitials = e;
                        setSearch(search_c)
                    }
                }}  onChange={(e) => { handleChange(e) }} >
                    {
                        Boolean(lnitialsData) &&
                        lnitialsData.map((item: any) => {
                            return (
                                <TabPane tab={item} key={item}>
                                    {renderTable()}
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
        )
    }

    function renderTable() {
        function goUpload(id: string) {
            brandDelete({id}).then(res => {
                getBrandList()
            })
        }
        const columns: any = [
            {
                title: '名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'logo',
                dataIndex: 'logo',
                key: 'logo',
                render: (status:string,record:any)=>{
                    return (
                        <img style={{width:'5vw',height:'5vw',objectFit: 'cover'}} className='brandLogo' src={status} />
                    )
                }
            },
            {
                title: '品牌类型',
                dataIndex: 'classify',
                key: 'classify',
            },
            {
                title: '内容数量',
                dataIndex: 'articleNum',
                key: 'articleNum',
            },
            {
                title: '近7日更新',
                dataIndex: 'last7DaysUpdate',
                key: 'last7DaysUpdate',
            },
            {
                title: '关注用户',
                dataIndex: 'initFocusNum',
                key: 'initFocusNum',
            },
            {
                title: '近7日阅读',
                dataIndex: 'last7DaysReadNum',
                key: 'last7DaysReadNum',
            },
            {
                title: '近7日点赞',
                dataIndex: 'last7DaysLikeNum',
                key: 'last7DaysLikeNum',
            },
            {
                title: '近7日分享',
                dataIndex: 'last7DaysShareNum',
                key: 'last7DaysShareNum',
            },
            {
                title: '操作',
                dataIndex: 'id',
                width: 120,
                key: 'id',
                render:(id:any,record:any)=>{
                    let item = {...record};
                    return(
                        <div className={styles.caozuoBtn}>
                            <a onClick={()=>{ setNowItem(item) }}>修改</a>

                            <Popconfirm placement="top" title={'确定删除吗'} onConfirm={()=>{goUpload(id) }} okText="Yes" cancelText="No">
                                <a href='#' >删除</a>
                            </Popconfirm>
                        </div>
                    )
                }
            },
        ]

        return (
            <div className={styles.table}>
                <Table
                    pagination={false}
                    dataSource={brandData ? brandData : null}
                    columns={columns}
                ></Table>

            </div>
        )
    }

    function renderHandle() {
        const { TextArea } = Input;
        function addItem() {
            let item = {...nowItem};
            item.nationality = nowItem.nationality?nowItem.nationality:'欧美'
            item.level = nowItem.level?nowItem.level:1
            item.classify = nowItem.classify?nowItem.classify:'美妆护肤'
            if(!item.nationality){
                notification.error({
                    message: `请填写完整`,
                });
                return
            }
            if(!item.classify){
                notification.error({
                    message: `请填写完整`,
                });
                return
            }
            if(!item.country){
                notification.error({
                    message: `请填写完整`,
                });
                return
            }
            if(!item.description){
                notification.error({
                    message: `请填写完整`,
                });
                return
            }
            if(!item.lnitials){
                notification.error({
                    message: `请填写完整`,
                });
                return
            }
            if(!item.logo){
                notification.error({
                    message: `请填写完整`,
                });
                return
            }
            if(!item.name){
                notification.error({
                    message: `请填写完整`,
                });
                return
            }

            if(!item.subName){
                notification.error({
                    message: `请填写完整`,
                });
                return
            }
            if(!item.level){
                notification.error({
                    message: `请填写完整`,
                });
                return
            }
            if (item.id) {
                handleBrand(item).then(res => {
                    setNowItem(null)
                })
            } else {
                addBrand(item).then(res => {
                    setNowItem(null)
                })
            }

        }
        function beforeUpload(file: any) {
            getOSSSignature(file, '', file.name).then(res => {
                onChange_Form_Name('logo', res.fileURL)
            })
        }
        function onChange_Form_Name(type: string, value: any) {
            var regex = new RegExp("^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z_]){1,20}$");

            if(type=='lnitials'){
                if(!regex.test(value) && value!=''){
                    value='#'
                }
            }

            let item = { ...nowItem };
            item[type] = value;
            setNowItem(item)
        }
        return (
            <div className={styles.articleHandle}>
                <div className={styles.articleHandleHeader}>
                    <Button size={'middle'} type='primary' onClick={() => { setNowItem(null) }}>{'<<返回'}</Button>
                    <Button size={'middle'} type='primary' onClick={() => { addItem() }}>保存</Button>
                </div>
                <Row>
                    <Col span={16} offset={4}>
                        <Form
                            ref={formRef}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                            initialValues={nowItem}
                        >
                            <Form.Item label="名称" name="name">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.name}
                                        placeholder='请输入名称'
                                        maxLength={50}
                                        onChange={(e) => { onChange_Form_Name('name', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label="副标题" name="subName">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.subName}
                                        placeholder='请输入副标题'
                                        maxLength={50}
                                        onChange={(e) => { onChange_Form_Name('subName', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label="别名(选填)" name="otherName">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.otherName}
                                        placeholder='请输入别名'
                                        maxLength={50}
                                        onChange={(e) => { onChange_Form_Name('otherName', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label="首字母" name="lnitials">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.lnitials}
                                        placeholder='请输入首字母'
                                        maxLength={1}
                                        onChange={(e) => { onChange_Form_Name('lnitials', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label="所属集团" name="parentCompany">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.parentCompany}
                                        placeholder='请输入集团'
                                        onChange={(e) => { onChange_Form_Name('parentCompany', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item label="国家" name="country">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.country}
                                        placeholder='请输入国家'
                                        onChange={(e) => { onChange_Form_Name('country', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item label="logo" name="logo">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            beforeUpload={(file) => beforeUpload(file)}
                                        >
                                            {nowItem && nowItem.logo ? <img src={nowItem.logo} alt="avatar" style={{ width: '100px', height: '100px' }} /> :
                                                <PlusOutlined />}
                                        </Upload>
                                    </div>
                                </div>
                            </Form.Item>


                            <Form.Item label="品牌国籍" name="nationality">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('nationality', e.target.value) }}  defaultValue={'欧美'} value={nowItem?nowItem.nationality:'欧美'}>
                                            <Radio value={'欧美'}>欧美</Radio>
                                            <Radio value={'中国'}>中国</Radio>
                                            <Radio value={'日韩'}>日韩</Radio>
                                            <Radio value={'其他'}>其他</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="品牌级别" name="level">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('level', e.target.value) }}  defaultValue={0} value={nowItem.level}>
                                            <Radio value={0}>顶级</Radio>
                                            <Radio value={1}>一级</Radio>
                                            <Radio value={2}>二级</Radio>
                                            <Radio value={3}>三级</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="品牌主营" name="classify">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('classify', e.target.value) }} defaultValue={'美妆护肤'}  value={nowItem.classify}>
                                            <Radio value={'美妆护肤'}>美妆护肤</Radio>
                                            <Radio value={'美食'}>美食</Radio>
                                            <Radio value={'宠物'}>宠物</Radio>
                                            <Radio value={'母婴'}>母婴</Radio>
                                            <Radio value={'生活'}>生活</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="品牌描述" name="description">
                                <div className={styles.fromItem}>
                                    <div style={{ width:'100%' }} >
                                        <TextArea value={nowItem.description} showCount maxLength={150} style={{ width:'100%', height: 120 }} onChange={(e)=>{onChange_Form_Name('description', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div className={styles.articleManege}>
            {
                Boolean(!nowItem) &&
                <React.Fragment>
                    {renderHeader()}
                    {renderNav()}
                </React.Fragment>
            }
            {
                Boolean(nowItem) &&
                <React.Fragment>
                    {renderHandle()}
                </React.Fragment>
            }
        </div>
    )
};

export default ArticleManege;
