import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
import ArticleManege_Table from "./table";
import {
    Select,
    Input,
    Tabs,
    Table,
    Pagination,
    Button,
    Form,
    Upload,
    Radio,
    Row,
    Col,
    Checkbox,
    DatePicker,
    notification,
    List,
    Card,
} from 'antd';
const { Meta } =Card
import { getOSSSignature } from "@/services/uplaodImgOss";
import { PlusOutlined,BulbOutlined } from '@ant-design/icons';
import { articleEditorList } from "@/services/article";
import moment from 'moment'
import {addArticle, articleUpOrDown, getAllClassifys, handleArticle} from "@/services/articleManage";
import {copyTpwd, searchTao,channlUrl} from "@/services/copytao";
import {addBanner} from "@/services/bannerManage";
import {addBrand, handleBrand} from "@/services/brandManage";
import {activityInfo, activityUpdate} from "@/services/superTao";

const { Option } = Select;
const ArticleManege: React.FC = () => {
    const [search, setSearch] = useState<any>({
        text: '',
        hasCoupon:'',
        needFreeShipment:'',
        isOverseas:'',
        pageNum: 0,
        pageSize: 24,
        order:'match'
    });
    const [tableTotal, setTableTotal] = useState(0);

    const [nowItem, setNowItem] = useState<any>(null);


    let formRef = useRef<any>()

    useEffect(() => {
        getAllClassInfo();
    }, [])

    useEffect(() => {
        if (!nowItem) {
        }

    }, [search, nowItem])


    function getAllClassInfo() {
        activityInfo().then(res => {
            let resData = res.datas ? res.datas : [];
            setNowItem(resData)
        })
    }


    function renderHandle(){
        const { TextArea } = Input;

        function onChange_Form_Name(type: string, value: any) {
            let item = { ...nowItem };
            item[type] = value;
            setNowItem(item)
        }
        function beforeUpload(file: any) {
            getOSSSignature(file, '', file.name).then(res => {
                onChange_Form_Name('activityImg', res.fileURL)
            })
        }
        function addItem() {
            let item = { ...nowItem };

            if(item.status==2){
                if(!item.activityImg){
                    notification.error({
                        message:'请完善活动信息'
                    })
                    return;
                }
                if(item.activityList.split(',').length<8){
                    notification.error({
                        message:'请完善活动信息'
                    })
                    return
                }
            }
            activityUpdate(item).then(res => {
                notification.success({
                    message:'更新成功'
                })
            })
        }
        return (<div className={styles.superTaoForm}>
            <div>
                <Form.Item label="" name="activityImg">
                    <div className={styles.fromItem}>
                        <div className={styles.left}>
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={(file) => beforeUpload(file)}
                            >
                                {nowItem && nowItem.activityImg ? <img src={nowItem.activityImg} alt="avatar" style={{ width: '100px', height: '100px' }} /> :
                                    <PlusOutlined />}
                            </Upload>
                        </div>
                    </div>
                </Form.Item>
                <Form.Item label="" labelCol={{span:24}} colon={true} name="status">
                    <div className={styles.fromItem}>
                        <div>
                            <Radio.Group onChange={(e) => { onChange_Form_Name('status', e.target.value) }} value={nowItem?nowItem.status:''}>
                                <Radio value={0}>下线</Radio>
                                <Radio value={1}>预览</Radio>
                                <Radio value={2}>上线</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                </Form.Item>
                <Button size={'middle'} type='primary' onClick={() => {
                    addItem()
                }}>保存</Button>
            </div>
            <div className={styles.right}>
                <TextArea placeholder='请输入活动id列表' value={nowItem?nowItem.activityList:''}  style={{ width:'50vw', height: "100%" }} onChange={(e)=>{onChange_Form_Name('activityList', e.target.value) }} />
            </div>
        </div>)
    }
    return (
        <div className={styles.articleManege}>
            <React.Fragment>
                {renderHandle()}
            </React.Fragment>


        </div>
    )
};

export default ArticleManege;
