import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import styles from "../index.module.less";
import {loginApp} from '@/services/login'
import { useHistory } from "react-router";
/**
 * 密码登录
 *
 */

const LoginPassword = () => {
  const [formData, setFormData] = useState({ account:'',pwd: '' });
  const history = useHistory();

  function goLoagin(account:string,pwd:string){
    loginApp({account: account,
      pwd: pwd}).then( res =>{
        if (res.code == '000') {
          const userInfo = res.datas;
          window.localStorage.setItem('userInfo',JSON.stringify(userInfo));
          window.localStorage.setItem('token',userInfo.token);
          message.success('OK，登录成功')
          history.push("/dataBoard");
        }else{
          message.error('Sorry，'+res.message)
        }
      })
  }

  function changeFormData(type:'account'|'pwd',e:any){
    const value:string = e.target.value;
    const formData_c:{account:string,pwd: string} = {...formData};
    formData_c[type] = value;
    setFormData(formData_c);
  }

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.loginTitle}>登陆</div>
      </div>
      <Form>
        <Form.Item
          name="user_info"
          rules={[{ required: true, message: "用户名/手机号/邮箱不能为空" }]}
        >
          <Input
            value={formData.account}
            className={styles.input}
            placeholder={"用户名/手机号/邮箱"}
            maxLength={128}
            size="large"
            onChange={ (e)=>{ changeFormData('account',e) } }
          />
        </Form.Item>
        <Form.Item
          name="user_check"
          rules={[{ required: true, message: "密码不能为空" }]}
        >
          <Input
            value={formData.pwd}
            className={styles.input}
            type="password"
            placeholder="密码"
            maxLength={128}
            size="large"
            onChange={ (e)=>{ changeFormData('pwd',e) } }
          />
        </Form.Item>
        <Button
          className={styles.loginButton}
          type="primary"
          htmlType="submit"
          size="large"
          block
          onClick={()=>{  goLoagin(formData.account,formData.pwd) }}
        >
          登陆
        </Button>
      </Form>
    </div>
  );
};

export default LoginPassword;
