import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
import { addArticle, articleGoTop, articleList, articleUpOrDown, getAllClassifys, getBrandList, getItemData, handleArticle } from "@/services/articleManage";
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';

import ArticleManege_Table from "./table";
import {
    Select,
    Input,
    Tabs,
    Table,
    Pagination,
    Button,
    Form,
    Upload,
    Radio,
    Row,
    Col,
    Checkbox,
    Modal,
    DatePicker,
    Popconfirm, Image, AutoComplete, notification
} from 'antd';
import { getOSSSignature } from "@/services/uplaodImgOss";
import { PlusOutlined, BulbOutlined } from '@ant-design/icons';
import { articleEditorList } from "@/services/article";
import moment from 'moment'
import ArticleManege_Editor from "../articleManege/editor";
import {
    addsku,
    oneList,
    queryThirdData,
    skuClassifyList,
    skuinfo,
    skuInfoDel,
    skuInfoSearch,
    updatesku
} from "@/services/oneManage";
import {getChatList, groupAdd, groupDelete, groupList, groupUpdate} from "@/services/classManege";

const { Option } = Select;
const ArticleManege: React.FC = (props:any) => {
    const [search, setSearch] = useState<any>({
        pageNum: 1,
        pageSize: 20,
        title: '',
        type: '',
        descOrAsc:'',
        indexName:'',
        name:'',
        typeSearch:1
    });
    const [tableTotal, setTableTotal] = useState(0);
    const [typeInfo, setTypeInfo] = useState<any>(null);
    const [twotypeInfo, settwoTypeInfo] = useState<any>(null);
    const [twoClass, setTwoClass] = useState<any>(null);
    const [Options, setOptions] = useState<any>(null);
    const [brandInfo, setBrandInfo] = useState<any>(null);
    const [editorCodeArr, setEditorCodeArr] = useState<any>([])
    const [Data, setData] = useState<any>(null);
    const [delName, setDelName] = useState<any>(null);
    const [isModalVisible, setisModalVisible] = useState<any>(null);


    const [nowItem, setNowItem] = useState<any>(props.children?JSON.parse(props.children):null);
    let formRef = useRef<any>()
    const inputRef: any = useRef(null)

    useEffect(() => {
        getAllClassInfo();
        if(props.children){
            getAllClassInfo('')
        }
    }, [])

    useEffect(() => {
        if (!nowItem && search.typeSearch) {
            getGroupList()
        }


    }, [search, nowItem])

    function getAllClassInfo(oneClassify?:any,type?:any) {
        let param={
            classify:oneClassify,
            recommend:1
        }
        skuClassifyList(param).then(res => {
            let resData = res.datas ? res.datas : [];
            if(oneClassify){
                if(type){
                    setTwoClass(resData)
                }else{
                    settwoTypeInfo(resData)
                }
            }else{
                setTypeInfo(resData)
            }
        })
    }



    function getGroupList() {
        groupList({ ...search }).then(res => {
            let resData = res.datas;
            setTableTotal(resData.total);
            setData(resData)
        })
    }

    function renderHeader() {
        function handleChange(type: string, value: number) {
            if(type=='oneClassify'){
                getAllClassInfo(value)
            }
            let search_c = { ...search };
            search_c[type] = value;
            setSearch(search_c)
        }
        function onChangeinput(e: string) {
            let search_c = { ...search };
            search_c.name = e;
            search_c.typeSearch = 0;
            setSearch(search_c)
        }

        return (
            <div className={styles.articleManageHeaderBox}>
                <div className={styles.articleManageHeader}>
                    <div>
                        <Input value={search ? search.name : ''} onChange={(e) => { onChangeinput(e.target.value) }} placeholder="输入关键词" />
                        <Button onClick={()=>{
                            getGroupList()
                        }}>搜索</Button>
                    </div>
                </div>
                <Button size={'middle'} type='primary' onClick={() => {
                    setNowItem({
                        content: '<p></p>',
                    })
                 }}>新建团长</Button>
            </div>
        )
    }

    function renderTable() {

        function delItem(id: string,name:string) {
            if(delName==name){
                groupDelete(id).then(res => {
                    getGroupList()
                    setisModalVisible(false)
                    setDelName('')
                })
            }

        }
        function getItem(resData: string) {
            setNowItem(resData)

        }
        function onChange_Form_Name(value: any) {
            setDelName(value)

        }

        const columns: any = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '团长',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '群数量',
                dataIndex: 'groupInfoRecordList',
                key: 'groupInfoRecordList',
                render: (id: any, record: any) => {
                    return (
                        <div className={styles.caozuoBtn}>
                            {record.groupInfoRecordList.length}
                        </div>
                    )
                }
            },
            {
                title: '团员数',
                dataIndex: 'memberNum',
                key: 'memberNum',
                sorter:'memberNum',
            },
            {
                title: '子团长',
                dataIndex: 'childGroupNum',
                key: 'childGroupNum',
                sorter:'childGroupNum',
            },
            {
                title: '今日订单',
                dataIndex: 'todayOrderNum',
                key: 'todayOrderNum',
                sorter:'todayOrderNum',
            },
            {
                title: '今日预估收入',
                dataIndex: 'todayAmount',
                key: 'todayAmount',
                sorter:'todayAmount',
            },
            {
                title: '本月订单',
                dataIndex: 'monthOrderNum',
                key: 'monthOrderNum',
                sorter:'monthOrderNum',
            },
            {
                title: '本月预估收入',
                dataIndex: 'monthAmount',
                key: 'monthAmount',
                sorter:'monthAmount',
            },
            {
                title: '上月收入',
                dataIndex: 'lastMonthAmount',
                key: 'lastMonthAmount',
                sorter:'lastMonthAmount',
            },
            {
                title: '操作',
                dataIndex: 'id',
                width: 170,
                key: 'id',
                render: (id: any, record: any) => {
                    return (
                        <div className={styles.caozuoBtn}>
                            <a onClick={() => { getItem(record) }}>编辑</a>
                            <a onClick={() => { setisModalVisible(true) }}  style={{color:'red'}}>删除</a>
                            <Modal title="dle Gruop big"
                                   okText='删除'
                                   visible={isModalVisible} onOk={()=>{delItem(record.id,record.name) }} onCancel={()=>{setisModalVisible(false);setDelName('')}}>
                                <Input placeholder='请输入团长名称后删除' value={delName} onChange={(e) => { onChange_Form_Name( e.target.value) }} />
                            </Modal>
                        </div>
                    )
                }
            },
        ]
        function onChangePagination(page: number, pageSize: number) {
            let search_c = { ...search };
            search_c.pageSize = pageSize;
            search_c.pageNum = page;
            setSearch(search_c)
        }
        interface DataType {
            name: string;
            monthOrderNum: string;
        }
        const handleTableChange = (newPagination:any,filters:any, sorter:any) => {
            let items={...search}
            console.log(sorter)
            items['descOrAsc']=sorter.order=='ascend'?'asc':sorter.order=="descend"?'desc':''
            items['indexName']=sorter.field
            setSearch(items)


        };
        return (
            <div className={styles.table}>
                <Table
                    pagination={false}
                    dataSource={Data ? Data.list : null}
                    columns={columns}
                    onChange={handleTableChange}
                ></Table>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '2rem' }}>
                    <Pagination
                        showSizeChanger
                        showQuickJumper
                        showTotal={total => `总量： ${total} 条`}
                        pageSizeOptions={[10, 20, 50, 100, 1000]}
                        onChange={(page, pageSize) => { onChangePagination(page, pageSize) }}
                        pageSize={search.pageSize}
                        current={search.pageNum}
                        total={tableTotal}
                    />
                </div>
            </div>
        )
    }

    function renderHandle() {
        function addItem(num:string) {
            let item = { ...nowItem };

            if (item.id) {
                groupUpdate(item).then(res => {
                    notification.success({
                        message:'更新成功'
                    })
                    setNowItem(null)
                })

            } else {
                groupAdd(item).then(res => {
                    notification.success({
                        message:'添加成功'
                    })
                    setNowItem(null)
                })

            }
        }
        function beforeUpload(file: any) {
            getOSSSignature(file, '', file.name).then(res => {
                onChange_Form_Name('headImgUrl', res.fileURL)
            })
        }
        function getClassInfo(oneClassify?:any) {
            let param={
                classify:oneClassify
            }
            skuClassifyList(param).then(res => {
                let resData = res.datas ? res.datas : [];
                setTwoClass(resData)
            })
        }
        function onChange_Form_Name(type: string, value: any) {
            let item = { ...nowItem };
            item[type] = value;
            setNowItem(item)
            if(type=='oneClassify'){
                getClassInfo(value)
            }
        }


        const onChange = (data: string) => {
            let item = { ...nowItem };
            item['title'] = data;
            setNowItem(item)

        };
        const onSearch = (searchText: string) => {
            getChatList(searchText).then(res => {
                let data=res.datas
                data=data.map((e:any)=>({...e,['value']:e.name}))
                setOptions(data)
            })
        };

        const onSelect = (data: string) => {
            let chatId=''
            Options.map((e:any)=>{
                if(e.name==data){
                    chatId=e.chatId
                }
            })
            let item = { ...nowItem };

            if(item['groupInfoRecordList']){
                let falses=true
                item['groupInfoRecordList'].map((e:any)=>{
                    if(e.name==data){
                        falses=false
                        return
                    }
                })
                if(falses){
                    item['groupInfoRecordList'].push({chatId:chatId,name:data});
                }
            }else{
                item['groupInfoRecordList']=[{chatId:chatId,name:data}];
            }
            setNowItem(item)

        };

        return (
            <div className={styles.articleHandle}>

                <Row>
                    <Col span={24}>
                        <Form
                            ref={formRef}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                            initialValues={nowItem}
                        >
                            {nowItem && nowItem.id && <Form.Item label="团长ID" name="name">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.name}
                                        readOnly={true}
                                        onChange={(e) => {
                                            onChange_Form_Name('name', e.target.value)
                                        }}
                                    />
                                </div>
                            </Form.Item>}
                            <Form.Item label="上级团长用户ID" name="parentUserId">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.parentUserId}
                                        onChange={(e) => { onChange_Form_Name('parentUserId', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label="团长用户ID" name="userId">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input value={nowItem.userId} onChange={(e) => { onChange_Form_Name('userId', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="团长名称" name="name">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input value={nowItem.name} onChange={(e) => { onChange_Form_Name('name', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="淘宝PID" name="tbPid">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input value={nowItem.tbPid} onChange={(e) => { onChange_Form_Name('tbPid', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="微信群" name="alias">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input.Group compact>

                                            <AutoComplete
                                                onSelect={onSelect}
                                                onSearch={onSearch}
                                                onChange={onChange}
                                                style={{width:'100%'}}
                                                value={nowItem.title}
                                                placeholder="查找群"
                                                options={Options}
                                            />
                                        </Input.Group>
                                        {/*<Input value={nowItem.chatId} onChange={(e) => { onChange_Form_Name('chatId', e.target.value) }} />*/}
                                        {nowItem && nowItem.groupInfoRecordList && <div className={styles.classTag}>
                                            {nowItem.groupInfoRecordList.map((e:any,i:any)=>(<span onClick={()=>{
                                                let item={...nowItem}
                                                item.groupInfoRecordList.splice(i,1)
                                                setNowItem(item)
                                            }}>
                                                 <span>{e.name}</span>
                                                <span>×</span>
                                            </span>))}


                                        </div>}
                                    </div>

                                </div>
                            </Form.Item>

                        </Form>
                    </Col>

                </Row>
                <div className={styles.articleHandleHeader}>
                    <div><Button size={'middle'} type='primary' onClick={() => { setNowItem(null) }}>{'取消'}</Button></div>
                    <div>
                        <Button style={{marginLeft:'1rem'}} size={'middle'} type='primary' onClick={() => { addItem('1') }}>保存</Button>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={styles.articleManege}>
            {
                Boolean(!nowItem) &&
                <React.Fragment>
                    {renderHeader()}
                    {renderTable()}
                </React.Fragment>
            }
            {
                Boolean(nowItem) &&
                <React.Fragment>
                    {renderHandle()}
                </React.Fragment>
            }
        </div>
    )
};

export default ArticleManege;
