import { responseInterceptor, resultType, whyRequest, _getParam } from "../utils/reuqest";

/**
 * 登陆接口
 * @param params
 */

 export interface ILoginData {
  account: string;
  pwd: string;
}

export const loginApp = async(params:ILoginData) => {
  const reslutData = await whyRequest.get(`/admin/api/user/login?${_getParam(params)}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}

