import React, { useState } from "react";
import styles from "./index.module.less";
import OpenList from "../openList/index";

const childPage: React.FC = () => {

    return (
      <div className={styles.childCon}>
          <OpenList></OpenList>
      </div>
  );
};

export default childPage;
