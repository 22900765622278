import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
import { addArticle, articleGoTop, articleList, articleUpOrDown, getAllClassifys, getBrandList, getItemData, handleArticle } from "@/services/articleManage";
import ArticleManege_Table from "./table";
import {
    Select,
    Input,
    Tabs,
    Table,
    Pagination,
    Button,
    Form,
    Upload,
    Radio,
    Row,
    Col,
    Checkbox,
    DatePicker,
    Popconfirm, Image, notification
} from 'antd';
import { getOSSSignature } from "@/services/uplaodImgOss";
import { PlusOutlined, BulbOutlined } from '@ant-design/icons';
import { articleEditorList } from "@/services/article";
import moment from 'moment'
import {addsku, oneList, queryThirdData, skuClassifyList, skuinfo, skuInfoDel, updatesku} from "@/services/oneManage";
import {fetchWordsAdd, fetchWordsDel, fetchWordsList, fetchWordsUpdate, SourceList} from "@/services/shopIdManege";

const { Option } = Select;
const ArticleManege: React.FC = (props:any) => {
    const [search, setSearch] = useState<any>({
        brandId: null,
        pageNum: 0,
        pageSize: 20,
        status: null,
        title: '',
        type: '全部',
    });
    const [tableTotal, setTableTotal] = useState(0);
    const [typeInfo, setTypeInfo] = useState<any>(null);
    const [twotypeInfo, settwoTypeInfo] = useState<any>(null);
    const [twoClass, setTwoClass] = useState<any>(null);
    const [brandInfo, setBrandInfo] = useState<any>(null);
    const [Data, setData] = useState<any>(null);
    const [nowItem, setNowItem] = useState<any>(null);
    const [source, setSource] = useState<any>(null);
    let formRef = useRef<any>()
    const inputRef: any = useRef(null)

    useEffect(() => {
        getAllClassInfo();
        getfetchWordsList();
        getSourceList();
        getBrandInfo()
    }, [])

    useEffect(() => {
        if (!nowItem) {
            getfetchWordsList();
        }


    }, [search, nowItem])

    function getBrandInfo() {
        getBrandList().then(res => {
            let resData = res.datas;
            resData.unshift({ id: null, name: '全部', subName: '' })
            setBrandInfo(resData)
        })
    }
    function getAllClassInfo(oneClassify?:any,type?:any) {
        let param={
            classify:oneClassify,
        }
        skuClassifyList(param).then(res => {
            let resData = res.datas ? res.datas : [];
            if(oneClassify){
                if(type){
                    setTwoClass(resData)
                }else{
                    settwoTypeInfo(resData)
                }
            }else{
                setTypeInfo(resData)
            }
        })
    }

    function getfetchWordsList() {
        fetchWordsList(search).then(res => {
            let resData = res.datas;
            setData(resData)
        })
    }

    function getSourceList() {
        SourceList().then(res => {
            let resData = res.datas;
            resData.push('')
            setSource(resData)
        })
    }

    function renderHeader() {
        function handleChange(type: string, value: number) {
            let search_c = { ...search };
            search_c[type] = value;
            setSearch(search_c)
        }
        function onChangeinput(e: string,type:string) {
            let search_c = { ...search };
            search_c[type] = e;
            setSearch(search_c)
        }

        return (
            <div className={styles.articleManageHeaderBox}>
                <div className={styles.articleManageHeader}>
                    <div>
                        <div className={styles.title}>品牌：</div>
                        {
                            Boolean(brandInfo) &&
                            <Select showSearch optionFilterProp="children" value={search.brandId} style={{ width: 170 }} onChange={(value: number) => { handleChange('brandId', value) }}>
                                {
                                    brandInfo.map((item: any) => {
                                        return (
                                            <Option value={item.id} key={item.id}>{item.name + '  ' + item.subName}</Option>
                                        )
                                    })
                                }
                            </Select>
                        }
                    </div>

                    <div>
                        <Input value={search ? search.remark : ''} onChange={(e) => { onChangeinput(e.target.value,'remark') }} placeholder="输入备注" />
                    </div>
                    <div>
                        <Input value={search ? search.fetchWord : ''} onChange={(e) => { onChangeinput(e.target.value,'fetchWord') }} placeholder="输入关键词" />
                    </div>
                    <div>
                        {
                            Boolean(source) &&
                            <Select showSearch optionFilterProp="children" value={search.source} style={{ width: 170 }} onChange={(value: number) => { handleChange('source', value) }}>
                                {
                                    source.map((item: any) => {
                                        return (
                                            <Option value={item} key={item}>{item}</Option>
                                        )
                                    })
                                }
                            </Select>
                        }
                    </div>
                </div>
                <Button size={'middle'} type='primary' onClick={() => {
                    setNowItem({
                        content: '',
                    })
                }}>新建店铺</Button>
            </div>
        )
    }

    function renderTable() {

        function delItem(id: string) {
            fetchWordsDel({id}).then(res => {
                getfetchWordsList()
            })
        }
        function getItem(resData: any) {
            setNowItem(resData)
        }
        const columns: any = [

            {
                title: '品牌别名',
                dataIndex: 'brandAlias',
                key: 'brandAlias',
            },
            {
                title: '品牌ID',
                dataIndex: 'brandId',
                key: 'brandId',
            },
            {
                title: '关键词',
                dataIndex: 'fetchWord',
                key: 'fetchWord',
            },
            {
                title: '一级分类',
                dataIndex: 'oneClassify',
                key: 'oneClassify',
            },
            {
                title: '备注',
                dataIndex: 'remark',
                key: 'remark',
            },
            {
                title: '来源',
                dataIndex: 'source',
                key: 'source',
            },
            {
                title: '是否生效',
                dataIndex: 'status',
                key: 'status',
                render: (status: any, record: any) => {
                    return (<div>{status?'生效':'失效'}</div>)
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                width: 170,
                key: 'id',
                render: (id: any, record: any) => {
                    return (
                        <div className={styles.caozuoBtn}>
                            <a onClick={() => { getItem(record) }}>修改</a>
                            <Popconfirm placement="top" title={'确定删除吗'} onConfirm={()=>{delItem(id) }} okText="Yes" cancelText="No">
                                <a href='#' >删除</a>
                            </Popconfirm>
                        </div>
                    )
                }
            },
        ]
        function onChangePagination(page: number, pageSize: number) {
            let search_c = { ...search };
            search_c.pageSize = pageSize;
            search_c.pageNum = page;
            setSearch(search_c)
        }
        return (
            <div className={styles.table}>
                <Table
                    pagination={false}
                    dataSource={Data ? Data.list : null}
                    columns={columns}
                ></Table>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '2rem' }}>
                    <Pagination
                        showSizeChanger
                        showQuickJumper
                        showTotal={total => `总量： ${total} 条`}
                        pageSizeOptions={[10, 20, 50, 100, 1000]}
                        onChange={(page, pageSize) => { onChangePagination(page, pageSize) }}
                        pageSize={search.pageSize}
                        current={search.pageNum}
                        total={tableTotal}
                    />
                </div>
            </div>
        )
    }

    function renderHandle() {
        const { TextArea } = Input;
        function addItem(num:string) {
            let item = { ...nowItem };
            if (item.id) {
                fetchWordsUpdate(item).then(res => {
                    notification.success({
                        message: `成功`,
                    });
                    setNowItem(null)
                })

            } else {
                fetchWordsAdd(item).then(res => {
                    notification.success({
                        message: `成功`,
                    });
                    setNowItem(null)
                })
            }
        }
        function beforeUpload(file: any) {
            getOSSSignature(file, '', file.name).then(res => {
                onChange_Form_Name('headImgUrl', res.fileURL)
            })
        }
        function getClassInfo(oneClassify?:any) {
            let param={
                classify:oneClassify
            }
            skuClassifyList(param).then(res => {
                let resData = res.datas ? res.datas : [];
                setTwoClass(resData)
            })
        }
        function onChange_Form_Name(type: string, value: any) {
            let item = { ...nowItem };
            item[type] = value;
            setNowItem(item)
            if(type=='oneClassify'){
                getClassInfo(value)
            }
        }

        function searchComposition(){
            queryThirdData({name:nowItem.recordNo}).then(res => {
                let item = { ...nowItem };
                item['composition'] = res.datas.composition;
                setNowItem(item)
            })
        }

        return (
            <div className={styles.articleHandle}>
                <div className={styles.articleHandleHeader}>
                    <div><Button size={'middle'} type='primary' onClick={() => { setNowItem(null) }}>{'<<返回'}</Button></div>
                    <div>
                        <Button style={{marginLeft:'1rem'}} size={'middle'} type='primary' onClick={() => { addItem('1') }}>保存</Button>
                    </div>
                </div>
                <Row>
                    <Col span={12}>
                        <Form
                            ref={formRef}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                            initialValues={nowItem}
                        >
                            <Form.Item label="品牌别名" name="brandAlias">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.brandAlias}
                                        onChange={(e) => { onChange_Form_Name('brandAlias', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label="所属品牌" name="brandId">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Select showSearch optionFilterProp="children" value={nowItem.brandId} style={{ width: 170 }} onChange={(value: number) => { onChange_Form_Name('brandId', value) }}>
                                            {
                                                Boolean(brandInfo) &&
                                                brandInfo.map((item: any) => {
                                                    if (item.id) {
                                                        return (
                                                            <Option value={item.id} key={item.id}>{item.name + '  ' + item.subName}</Option>
                                                        )
                                                    }
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="一级分类" name="classify">
                                <div className={styles.fromItem}>
                                    <div>
                                        {Boolean(typeInfo) && <Select showSearch optionFilterProp="children" value={nowItem.oneClassify}
                                                                      style={{width: 170}} onChange={(value: any) => {
                                            onChange_Form_Name('oneClassify', value)
                                        }}>
                                            {
                                                typeInfo.map((item: any) => {
                                                    return (
                                                        <Option value={item.classify}
                                                                key={item.id}>{item.classify}</Option>
                                                    )
                                                })
                                            }
                                        </Select>}
                                    </div>
                                </div>
                            </Form.Item>
                            {/*<Form.Item label="优惠比例" name="couponRate">*/}
                            {/*    <div>*/}
                            {/*        <Input*/}
                            {/*            type="text"*/}
                            {/*            value={nowItem.couponRate}*/}
                            {/*            onChange={(e) => { onChange_Form_Name('couponRate', e.target.value) }}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</Form.Item>*/}
                            <Form.Item label="备注" name="remark">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.remark}
                                        onChange={(e) => { onChange_Form_Name('remark', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label="关键词" name="fetchWord">
                                <div>
                                    <Input
                                        type="text"
                                        value={nowItem.fetchWord}
                                        onChange={(e) => { onChange_Form_Name('fetchWord', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label="来源" name="name">
                                <div>
                                    <Select showSearch optionFilterProp="children" value={nowItem.source}  onChange={(value: number) => { onChange_Form_Name('source', value) }}>
                                        {
                                            Boolean(source) &&
                                            source.map((item: any) => {
                                                if (item) {
                                                    return (
                                                        <Option value={item} key={item}>{item}</Option>
                                                    )
                                                }
                                            })
                                        }
                                    </Select>
                                </div>
                            </Form.Item>
                        </Form>
                    </Col>

                </Row>
            </div>
        )
    }
    return (
        <div className={styles.articleManege}>
            {
                Boolean(!nowItem) &&
                <React.Fragment>
                    {renderHeader()}
                    {renderTable()}
                </React.Fragment>
            }
            {
                Boolean(nowItem) &&
                <React.Fragment>
                    {renderHandle()}
                </React.Fragment>
            }
        </div>
    )
};

export default ArticleManege;
