import React from "react";
import LoginPassword from "./components/LoginPassword";
import styles from "./index.module.less";

const LoginPage: React.FC = () => {
  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginLogo} />
      <div className={styles.loginForm}>
        <LoginPassword/>
      </div>
    </div>
  );
};

export default LoginPage;
