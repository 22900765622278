import {
  responseInterceptor,
  resultType, whyRequest, _getParam
} from "../utils/reuqest";

/**
 * 文章列表
 * @param params
 */
export interface ArticleType {
  pageNum: number,	//页码		
  pageSize: number, //	页数	
  brandId?: number, //品牌Id
  title?: string, //标题	
  type?: string,
  status?: string
}

export const articleList = async (params: ArticleType) => {
  const reslutData = await whyRequest.get(`/admin/api/article/list?${_getParam(params)}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}

/**
 * 分类列表
 * @param params
 */
export const getAllClassifys = async () => {
  const reslutData = await whyRequest.get(`/admin/api/article/getAllClassifys`);
  return responseInterceptor(reslutData) as Promise<resultType>
}

/**
 * 品牌列表
 * @param params
 */
export const getBrandList = async () => {
  const reslutData = await whyRequest.get(`/admin/api/brand/getBrandList`);
  return responseInterceptor(reslutData) as Promise<resultType>
}

/**
 * 创建
 * @param params
 */
export interface AddArticleType {
  activityType: string,
  articleType: number
  brandId: number
  channel: string
  classify: string
  contentId: number
  couponDesc: string
  expirationTime: string
  headerImgUrl: string
  startTime: string
  status: number
  title: string,
  id?: number
}

export const addArticle = async (params: AddArticleType) => {
  const reslutData = await whyRequest.post('/admin/api/article/add', { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}

export const handleArticle = async (params: AddArticleType) => {
  const reslutData = await whyRequest.post('/admin/api/article/update', { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}


/**
 * 置顶
 * @param params
 */
export const articleGoTop = async (params: { id: string }) => {
  const reslutData = await whyRequest.get('/admin/api/article/top', { params: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}

/**
 * 上下架
 * @param params
 */
 export const articleUpOrDown= async (params: { id: string }) => {
  const reslutData = await whyRequest.get('/admin/api/article/upOrDown', { params: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}

/**
 * 获取单挑数据详情
 * @param params
 */
 export const getItemData= async ( id: string ) => {
  const reslutData = await whyRequest.get(`/admin/api/article/info/${id}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}

/**
 * 删除优惠
 */
export const articleDel= async (id: number) => {
  const reslutData = await whyRequest.get(`/admin/api/article/delete?id=${id}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}

/**
 * 设置推荐
 * @param params
 */
export const recommendLevel= async (params: { id: string,recommendLevel:Number }) => {
  const reslutData = await whyRequest.get('/admin/api/article/recommendLevel', { params: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}
