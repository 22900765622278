import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import styles from "./index.module.less";
import { Layout, Menu, message } from "antd";
import { articleList, getAllClassifys } from "@/services/articleManage";
import { checkTpwdExist } from "@/services/article";
import Editor from './editor.js';
require('./style.less');


const ArticleManege_Editor = React.forwardRef<any,any>((props, ref) => {
    const editor:any = useRef()

    useImperativeHandle(ref, () => ({
        changeEditor:()=>{
            return editor.current.editor.getData()
        }
    }));
    const [Data, setData] = useState<any>(null);
    useEffect(()=>{
        console.log(props.Data)
        setData(props.Data)
    },[props.Data])

    useEffect( ()=>{
        if (Data) {
            creatEditor(Data);
        }
        return ()=>{
            clearEditor()
        }
    },[Data])

    function clearEditor(){
        if (editor.current) {
            editor.current.editor && editor.current.editor.destroy()
            // editor.current.editor.editing && editor.current.editor.editing.destroy()

        }
    }
    function creatEditor(Data:string) {
        let now_timer = new Date();
        let now_timerStr = now_timer.getFullYear() +'-'+ (now_timer.getMonth() + 1) +'-'+ now_timer.getDate()
        editor.current = new Editor({
            id: 'articleManege-editor-area',
            token: window.localStorage.getItem('token'),
            signUrl: `//admintbt.taobaitao.com/admin/api/oss/sign?dir=brand/${now_timerStr}/`,
            Data: Data?Data:'<p></p>',
            onchange: ()=>{

            },
            showImgRandomName:true
        })
        return editor.current
    }

    return(
        <div id={styles['articleManege-editor']}>
            <div id='articleManege-editor-area'></div>
        </div>
    )
})

export default ArticleManege_Editor;
