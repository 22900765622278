import React, { useState } from "react";
import styles from "./index.module.less";
import ArticleManege from "../articleManege/index";
import OneManege from "../oneManege/index";

const childPage: React.FC = () => {
    function GetQueryString(name:string) {
        return location.href.indexOf('03As1')!=-1?true:false
    }
    let item:any=sessionStorage.getItem('nowItem')
    return (
      <div className={styles.childCon}>
          {!Boolean(GetQueryString('type')) && <ArticleManege children={item}></ArticleManege>}
          {Boolean(GetQueryString('type')) && <OneManege children={item}></OneManege>}
      </div>
  );
};

export default childPage;
