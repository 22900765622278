import {
  responseInterceptor,
  resultType, whyRequest, _getParam
} from "../utils/reuqest";







export const fetchWordsList = async (params:any) => {
  const reslutData = await whyRequest.get(`/admin/api/fetchWords/list?${_getParam(params)}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}
export const fetchWordsDel = async (params:any) => {
  const reslutData = await whyRequest.get(`/admin/api/fetchWords/delete?${_getParam(params)}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}
export const fetchWordsAdd = async (params:any) => {
  const reslutData = await whyRequest.post(`/admin/api/fetchWords/add`, { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}
export const fetchWordsUpdate = async (params:any) => {
  const reslutData = await whyRequest.post(`/admin/api/fetchWords/update`, { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}
export const SourceList = async () => {
  const reslutData = await whyRequest.get('/admin/api/fetchWords/getSourceList');
  return responseInterceptor(reslutData) as Promise<resultType>
}

