import {
  responseInterceptor,
  resultType, whyRequest, _getParam
} from "../utils/reuqest";







export const searchTao = async (params: any) => {
  const reslutData = await whyRequest.get('/admin/api/jd/search', { params: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}


export const copyTpwd = async (params:any) => {
  const reslutData = await whyRequest.post(`/admin/api/jd/copyTpwd`, { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}
export const channlUrl = async (params:any) => {
  const reslutData = await whyRequest.post(`/admin/api/channlUrl/add`, { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}
export const createArticleBySearch = async (params:any) => {
  const reslutData = await whyRequest.post(`/admin/api/jd/createArticleBySearch`, { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}
