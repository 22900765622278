import React, { useEffect, useState } from "react";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/privateRouter";
import HomePage from "@/pages/home";
import LoginPage from "@/pages/login";
import ChildPage from "@/pages/childPage";
import ListChild from "@/pages/listChild";

import { routerArr } from './router'
import history from './history';

const RouterPage = (props:any) => {
  return (
    <Router history={history}>
      <Switch>
          <Route path={"/listChild"} component={ListChild} />
          <Route path={"/childPage"} component={ChildPage} />
        <Route path={"/login"} component={LoginPage} />
        <Route
          path="/"
          render={() => (
            <HomePage history={history}>
              <Switch>
                {
                  routerArr().router.map((item, index) => {
                    return (
                      <PrivateRoute path={item.path} component={item.componment} key={item.path} />
                    )
                  })
                }
                <Redirect to={routerArr().defaultRouter} />
              </Switch>
            </HomePage>
          )}
        />

      </Switch>
    </Router>
  );
};
export default RouterPage;
