import {
  responseInterceptor,
  resultType, whyRequest, _getParam
} from "../utils/reuqest";







export const getChatList = async (searchText:any) => {
  const reslutData = await whyRequest.get('/admin/api/group/getChatList?keyword='+searchText);
  return responseInterceptor(reslutData) as Promise<resultType>
}


export const groupAdd = async (params:any) => {
  const reslutData = await whyRequest.post(`/admin/api/group/add`, { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}

export const groupUpdate = async (params:any) => {
  const reslutData = await whyRequest.post(`/admin/api/group/update`, { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}

export const groupDelete = async (id:any) => {
  const reslutData = await whyRequest.get(`/admin/api/group/delete?id=${id}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}
export const groupList = async (params:any) => {
  const reslutData = await whyRequest.get(`/admin/api/group/list?${_getParam(params)}`);
  return responseInterceptor(reslutData) as Promise<resultType>
}

