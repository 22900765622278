import {
  responseInterceptor,
  resultType, whyRequest, _getParam
} from "../utils/reuqest";







export const activityInfo = async () => {
  const reslutData = await whyRequest.get('/admin/api/activity/info');
  return responseInterceptor(reslutData) as Promise<resultType>
}


export const activityUpdate = async (params:any) => {
  const reslutData = await whyRequest.post(`/admin/api/activity/update`, { data: params });
  return responseInterceptor(reslutData) as Promise<resultType>
}
