import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
import { addArticle, articleGoTop, articleList, articleUpOrDown, getAllClassifys, getBrandList, getItemData, handleArticle } from "@/services/articleManage";
import ArticleManege_Table from "./table";
import {
    Select,
    Input,
    Tabs,
    Table,
    Pagination,
    Button,
    Form,
    Upload,
    Radio,
    Row,
    Col,
    Checkbox,
    DatePicker,
    Popconfirm, Image
} from 'antd';
import { getOSSSignature } from "@/services/uplaodImgOss";
import { PlusOutlined, BulbOutlined } from '@ant-design/icons';
import { articleEditorList } from "@/services/article";
import moment from 'moment'
import ArticleManege_Editor from "./editor";
import {dailyList} from "@/services/todayManage";

const { Option } = Select;
const ArticleManege: React.FC = (props:any) => {
    const [search, setSearch] = useState<any>({
        idList: GetQuery('pushDate'),
    });
    const [tableTotal, setTableTotal] = useState(0);
    const [Data, setData] = useState<any>(null);
    const [nowItem, setNowItem] = useState<any>(props.children?JSON.parse(props.children):null);
    let formRef = useRef<any>()
    const inputRef: any = useRef(null)

    function GetQuery(key:any) {
        var search = location.href;
        return search.substring(search.indexOf('=')+1,search.indexOf('+)'))
    }
    useEffect(() => {
        if (!nowItem) {
            getOneList()
        }


    }, [search, nowItem])



    function getOneList() {
        GetQuery('idList')
        dailyList({ ...search }).then(res => {
            let resData = res.datas;
            setData(resData)
        })
    }

    function renderTable() {

        const columns: any = [
            {
                title: '头图',
                dataIndex: 'headImgUrl',
                key: 'headImgUrl',
                render: (status: string, record: any) => {
                    return (
                        <Image style={{width:'50px'}} src={record.headerImgUrl}/>
                    )
                }
            },
            {
                title: 'id',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '品牌',
                dataIndex: 'brandName',
                key: 'brandName',
            },
            {
                title: '一级分类',
                dataIndex: 'oneClassify',
                key: 'oneClassify',
            },
            {
                title: '二级分类',
                dataIndex: 'twoClassify',
                key: 'twoClassify',
            }
        ]

        return (
            <div className={styles.table}>
                <Table
                    pagination={false}
                    dataSource={Data ? Data : null}
                    columns={columns}
                ></Table>
            </div>
        )
    }

    return (
        <div className={styles.articleManege}>
            {
                Boolean(!nowItem) &&
                <React.Fragment>
                    {renderTable()}
                </React.Fragment>
            }
        </div>
    )
};

export default ArticleManege;
