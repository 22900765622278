import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
import {
    addArticle,
    articleDel,
    articleGoTop,
    articleList,
    articleUpOrDown,
    getAllClassifys,
    getBrandList,
    getItemData,
    handleArticle,
    recommendLevel
} from "@/services/articleManage";
import {oneList, skuClassifyList, skuinfo, skuInfoSearch} from "@/services/oneManage";
import history from '../../router/history';

import ArticleManege_Table from "./table";
import {
    Select,
    Input,
    Tabs,
    Table,
    Pagination,
    Button,
    Form,
    Upload,
    Radio,
    Row,
    Col,
    Checkbox,
    DatePicker,
    AutoComplete,
    Popconfirm, notification,
    Image,Popover,Modal
} from 'antd';
import { getOSSSignature } from "@/services/uplaodImgOss";
import { PlusOutlined, BulbOutlined } from '@ant-design/icons';
import { articleEditorList } from "@/services/article";
import moment from 'moment'
import ArticleManege_Editor from "../articleManege/editor";
import {getTask, submitTask, transferTask, userList} from "@/services/whereManege";

const { Option } = Select;
const ArticleManege: React.FC = (props:any, context) => {

    const [search, setSearch] = useState<any>({
        brandId: null,
        pageNum: 1,
        pageSize: 20,
        status: null,
        title: '',
        type: '我的',
        channelId:'',
        discount:0,
        page:3,

    });
    const [showType, setShowType] = useState<any>('待处理');
    const [tableTotal, setTableTotal] = useState(0);
    const [typeInfo, setTypeInfo] = useState<any>([
        {name:'待处理'},{name:'已上架'},{name:'已忽略'}
    ]);
    const [brandInfo, setBrandInfo] = useState<any>(null);
    const [editorCodeArr, setEditorCodeArr] = useState<any>([])
    const [Data, setData] = useState<any>(null);
    const [nowItem, setNowItem] = useState<any>(props.children?JSON.parse(props.children):null);
    const [task, setTask] = useState<any>(null);
    const [searchSku, setsearchSku] = useState<any>(null);
    const [Options, setOptions] = useState<any>(null);
    const [typeList, setTypeList] = useState<any>(null);
    const [twoTypeList, setTwoTypeList] = useState<any>(null);
    const [searchShop, setsearchShop] = useState<any>(null);
    const [userLists, setUserList] = useState<any>(null);
    const [isModalVisible, setVisible] = useState<any>(null);
    const [adminUserId, setAdminUserId] = useState<any>(null);





    let formRef = useRef<any>()
    const inputRef: any = useRef(null)

    useEffect(() => {
        getBrandInfo();
        getTaskData()
        getUserList()
    }, [])

    useEffect(() => {
        if (search.searchs) {
            getArticleList()
        }
    }, [search, nowItem])


    function getUserList() {
        userList().then(res => {
            let resData = res.datas;
            setUserList(resData)
        })
    }

    function getTaskData() {
        getTask().then(res => {
            let resData = res.datas;
            getSkuClass('')
            if(resData.data.oneClassify){
                getSkuClass(resData.data.oneClassify)
            }
            setTask(resData.task)
            setNowItem(resData.data)
        })
    }
    function getBrandInfo() {
        getBrandList().then(res => {
            let resData = res.datas;
            resData.unshift({ id: null, name: '全部品牌', subName: '' })
            setBrandInfo(resData)
        })
    }

    function getArticleList(type?:boolean) {
        let v={...search}
        if(type){
            v.pageNum=1
        }
        articleList({ ...v }).then(res => {
            let resData = res.datas;
            let search_c = { ...search };
            search_c.searchs = 0;
            setSearch(search_c)
            setTableTotal(resData.total);
            setData(resData)
        })
    }

    function getSkuClass(oneClassify:any,recommend?:any){
        let param={
            classify:oneClassify,
        }
        skuClassifyList(param).then(res => {
            let resData = res.datas;
            if(oneClassify){
                setTwoTypeList(resData)
            }else{
                setTypeList(resData)
            }
        })
    }
    function renderHeader() {


        return (
            <div className={styles.whereHead}>
                {task && <div>
                    HI,{task.adminUserName}
                </div>}
                <div>
                    <span>今日完成任务数</span>
                    {task && <div>
                        <span>{task.addCount}</span>
                        <span>/{task.targetCount}</span>
                    </div>}
                </div>
            </div>
        )
    }

    function renderNav() {
        const { TabPane } = Tabs
        function handleChange(value: any) {
            if(value=='已上架'){
                let sitem={...search}
                sitem['status']='1,2'
                sitem['searchs']=true
                setSearch(sitem)
            }
            if(value=='已忽略'){
                let sitem={...search}
                sitem['status']='-2'
                sitem['searchs']=true
                setSearch(sitem)
            }
            setShowType(value)
        }
        return (
            <div className={styles.articleManageNav}>
                <Tabs style={{ width: '100%' }} activeKey={showType} onChange={(e) => { handleChange(e) }} >
                    {
                        typeInfo.map((item: any,index:any) => {
                            return (
                                <TabPane tab={item.name} key={item.name}>
                                    {navs(index)}
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
        )
    }
   function navs(index:any){
        return (<div>
            {showType=='待处理' && nowItem && index==0 &&  renderSkuHandle()}
            {showType!='待处理' && renderTable()}
        </div>)
   }
    function renderTable() {
        function goTop(id: string) {
            articleGoTop({ id: id }).then(res => {
                getArticleList()
            })
        }
        function goUpload(id: string) {
            articleUpOrDown({ id }).then(res => {
                getArticleList()
            })
        }
        function goDel(id: number) {
            articleDel( id).then(res => {
                getArticleList()
            })
        }
        function getItem(id: string) {
            getItemData(id).then(res => {
                let resData = res.datas;
                if (resData.activityType) {
                    resData.activityType = resData.activityType.split('#')
                }
                if (!resData.content) {
                    resData.content = '<p></p>'
                }

                //resData.itemType=resData.skuInfoId?0:1
                sessionStorage.setItem('nowItem',JSON.stringify(resData))
                window['setNowItem']=()=>{

                }
                window.open('/#/childPage',"ziyemian",`width=1240,height=${window.screen.height},top=0,left=0,toobar=no,menubar=no,scrollbars=yes,location=no,status=no`)
                //setNowItem(resData)
            })
        }
        let columns: any
        if(showType=='已忽略'){
            columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '标题',
                    dataIndex: 'title',
                    key: 'title',
                    render: (title: string, record: any) => {

                        return (
                            <div className={styles.articleNitem}>
                                <Image width={75} src={record.headerImgUrl}/>
                                <div className={styles.articleNinfo}>
                                    <span>{title}</span>
                                    <span>{record.originalTitle}</span>
                                    <span>
                                    <Image width={25} src={record.channelLogo}/>
                                    <span>{record.brandName}</span>
                                </span>
                                </div>
                                {/*{Boolean(record.top) && <span className={styles.topStyle}></span>}*/}

                            </div>
                        )
                    }
                },
                {
                    title: '编辑时间',
                    dataIndex: 'updateTime',
                    key: 'updateTime',
                    render: (status: any, record: any) => {
                        return (<div>{moment(status).format('MM-DD HH:mm')}</div>)
                    }
                },
                {
                    title: '操作',
                    dataIndex: 'id',
                    width: 180,
                    key: 'id',
                    render: (id: any, record: any) => {
                        return (
                            <div className={styles.caozuoBtn}>
                                <a onClick={() => { getItem(id) }}>修改</a>
                            </div>
                        )
                    }
                },
            ]
        }else{
            columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '标题',
                    dataIndex: 'title',
                    key: 'title',
                    render: (title: string, record: any) => {

                        return (
                            <div className={styles.articleNitem}>
                                <Image width={75} src={record.headerImgUrl}/>
                                <div className={styles.articleNinfo}>
                                    <span>{title}</span>
                                    <span>{record.originalTitle}</span>
                                    <span>
                                    <Image width={25} src={record.channelLogo}/>
                                    <span>{record.brandName}</span>
                                </span>
                                </div>
                                {/*{Boolean(record.top) && <span className={styles.topStyle}></span>}*/}

                            </div>
                        )
                    }
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                    render: (status: string, record: any) => {
                        if (status == '-1') {
                            return (
                                <div><BulbOutlined style={{ color: '#e4393c' }} />待确认下架</div>
                            )
                        } else if (status == '0') {
                            return (
                                <div><BulbOutlined style={{ color: '#e4393c' }} />下架</div>
                            )
                        } else if (status == '1') {
                            return (
                                <div><BulbOutlined style={{ color: '#e4393c' }} />待确认上架</div>
                            )
                        } else if (status == '2') {
                            return (
                                <div><BulbOutlined style={{ color: 'rgb(82, 196, 26)' }} />上架</div>
                            )
                        } else if (status == '-99') {
                            return (
                                <div><BulbOutlined style={{ color: 'red' }} />草稿</div>
                            )
                        } else {
                            return null
                        }
                    }
                },
                {
                    title: '更新方式',
                    dataIndex: 'autoUpdate',
                    key: 'autoUpdate',
                    render: (status: string, record: any) => {
                        if (status == '1') {
                            return (
                                <div><BulbOutlined style={{ color: 'rgb(82, 196, 26)' }} />自动</div>
                            )
                        } else if (status == '0') {
                            return (
                                <div><BulbOutlined style={{ color: '#e4393c' }} />手动</div>
                            )
                        } else {
                            return null
                        }
                    }
                },
                {
                    title: '推荐',
                    dataIndex: 'recommendLevel',
                    key: 'recommendLevel',
                    render: (status: number, record: any) => {
                        let conn
                        if (status == 0) {
                            conn=<div><BulbOutlined style={{ color: '#999' }} />普通</div>
                        } else if (status == 1) {
                            conn=<div><BulbOutlined style={{ color: 'rgb(82, 196, 26)' }} />弱推</div>
                        }else if (status == 2) {
                            conn=<div><BulbOutlined style={{ color: 'rgb(82, 196, 26)' }} />中推</div>
                        }else if (status == 3) {
                            conn=<div><BulbOutlined style={{ color: 'rgb(82, 196, 26)' }} />强推</div>
                        }
                        function handleChangePush(e:number){
                            recommendLevel({
                                id:record.id,
                                recommendLevel:e
                            }).then(res => {
                            })
                        }
                        let cons=(<div className={styles.articleNpush}>
                            <span>更改推荐等级</span>
                            <div>
                                <Radio.Group   onChange={(e: any) => { handleChangePush(e.target.value) }} defaultValue={record.recommendLevel} buttonStyle="solid">
                                    <Radio.Button value={0}>普通</Radio.Button>
                                    <Radio.Button value={1}>弱推</Radio.Button>
                                    <Radio.Button value={2}>中推</Radio.Button>
                                    <Radio.Button value={3}>强推</Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>)
                        return (<Popover content={cons} trigger="click">
                            {conn}
                        </Popover>)
                    }
                },
                {
                    title: '浏览数',
                    dataIndex: 'readNum',
                    key: 'readNum',
                },
                {
                    title: '点赞数',
                    dataIndex: 'likeNum',
                    key: 'likeNum',
                },
                {
                    title: '推荐',
                    dataIndex: 'enterType',
                    key: 'enterType',
                    render: (status: number, record: any) => {
                        if (status == 1) {
                            return (
                                <div style={{color:'#FF7F50'}}>机器人</div>
                            )
                        } else if (status === 0) {
                            return (
                                <div>{record.userName}</div>
                            )
                        }else{
                            return (
                                <div style={{color:record.userName=='机器人'?'#FF7F50':''}}>{record.userName}</div>
                            )
                        }
                    }
                },
                {
                    title: '编辑时间',
                    dataIndex: 'updateTime',
                    key: 'updateTime',
                    render: (status: any, record: any) => {
                        return (<div>{moment(status).format('MM-DD HH:mm')}</div>)
                    }
                },
                {
                    title: '操作',
                    dataIndex: 'id',
                    width: 180,
                    key: 'id',
                    render: (id: any, record: any) => {
                        return (
                            <div className={styles.caozuoBtn}>
                                <a onClick={() => { getItem(id) }}>修改</a>
                                {/*<Popconfirm placement="top" title={record.top?'嗨确定取消置顶吗':'确定置顶吗'} onConfirm={()=>{goTop(id)}} okText="Yes" cancelText="No">*/}
                                {/*    <a href='#'>{record.top?'取消置顶':'置顶'}</a>*/}
                                {/*</Popconfirm>*/}

                                <a onClick={() => { goUpload(id) }}>{record.status == '2' ? '下架' : '上架'}</a>


                            </div>
                        )
                    }
                },
            ]
        }

        function onChangePagination(page: number, pageSize: number) {
            let search_c = { ...search };
            search_c.pageSize = pageSize;
            search_c.searchs = 1;
            search_c.pageNum = page;
            setSearch(search_c)
        }
        return (
            <div className={styles.table}>
                <Table
                    pagination={false}
                    dataSource={Data ? Data.list : null}
                    columns={columns}
                ></Table>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '2rem' }}>
                    <Pagination
                        showSizeChanger
                        showQuickJumper
                        showTotal={total => `总量： ${total} 条`}
                        pageSizeOptions={[10, 20, 50, 100, 1000]}
                        onChange={(page, pageSize) => { onChangePagination(page, pageSize) }}
                        pageSize={search.pageSize}
                        current={search.pageNum}
                        total={tableTotal}
                    />
                </div>
            </div>
        )
    }

    function renderSkuHandle() {
        const { TextArea } = Input;
        function addItem(num:string) {
            let item = { ...nowItem };
            item.activityType = nowItem.activityType ? nowItem.activityType.join('#') : ''
            item.status = num;
            item.content = inputRef.current.changeEditor();

            if (item.id) {
                submitTask(item).then(res => {
                    if(res.datas){
                        notification.success({message:'成功'})
                        setNowItem(null)
                        getTaskData()
                    }


                })
            } else {

            }
        }
        function onChange_Form_Name(type: string, value: any) {
            let item = { ...nowItem };
            if(type=='oneClassify' ||type=='twoClassify'){
                if(type=='oneClassify'){
                    item[type] = typeList[value].classify;
                    item[type+'Sid'] =typeList[value].sid;
                    getSkuClass(typeList[value].classify)
                }else{
                    item[type] = twoTypeList[value].classify;
                    item[type+'Sid'] =twoTypeList[value].sid;
                }
            }else{
                item[type] = value;
            }
            setNowItem(item)
        }
        function getNowItemCode(code: number) {
            let arr = [...editorCodeArr];
            arr.push(code)
            setEditorCodeArr(arr)
        }
        function ondown(e:any){
            if(e==13){
                let search = { ...searchShop };
                setsearchSku(search);
            }
        }
        const onChangeinput = (data: string) => {
            let item = { ...searchShop };
            item['word'] = data;
            setsearchShop(item)
        };
        const onChange = (data: string) => {
            let item = { ...nowItem };
            item['title'] = data;
            setNowItem(item)

        };
        const onSearch = (searchText: string) => {
            skuInfoSearch({word:searchText}).then(res => {
                let data=res.datas
                data=data.map((e:any)=>({...e,['value']:e.name+'-'+e.brandName}))
                setOptions(data)
            })
        };

        const onSelect = (data: string) => {
            let value='',title=''
            Options.map((e:any)=>{
                if(e.name+'-'+e.brandName+'-'+e.recordNo==data || e.name+'-'+e.brandName==data){
                    value=e.id
                    title=e.name
                }
            })
            skuinfo(value).then(res => {
                let item = { ...nowItem };
                item['content']=res.datas.content
                item['headerImgUrl']=res.datas.headImgUrl
                item['skuInfoId'] = value;
                item['title'] = title;
                item['oneClassify'] = res.datas.oneClassify?res.datas.oneClassify:''
                item['twoClassify'] = res.datas.twoClassify?res.datas.twoClassify:''
                setNowItem(item)
            })

        };
        function beforeUpload(file: any) {
            getOSSSignature(file, '', file.name).then(res => {
                onChange_Form_Name('headerImgUrl', res.fileURL)
            })
        }
        function handleOk(){
            let param={
                articleId:nowItem.id,
                adminUserId:adminUserId
            }
            transferTask(param).then(res => {
                notification.success({message:'已转交任务'})
                setNowItem(null)
                setVisible(false)
                getTaskData()
            })
        }
        function handleCancel(){
            setVisible(false)
        }
        return (
            <div className={styles.articleHandle}>

                <Row>
                    <Col span={9}>
                        <Form
                            ref={formRef}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 19 }}
                            layout="horizontal"
                            initialValues={nowItem}
                        >

                            <Form.Item  name="title">
                                <div className={styles.articleRest}>
                                    <Input.Group compact>

                                        <AutoComplete
                                            onSelect={onSelect}
                                            onSearch={onSearch}
                                            onChange={onChange}
                                            style={{width:'100%'}}
                                            value={nowItem.title}
                                            placeholder="查找商品"
                                            options={Options}
                                        />
                                    </Input.Group>
                                    <Button size={'middle'} type='primary' onClick={() => {
                                        let item = { ...nowItem };
                                        let item2 = { ...nowItem };
                                        item2['content']='<p></p>'
                                        setNowItem(item2)
                                        skuinfo(item.skuInfoId).then(res => {
                                            item['content']=res.datas.content
                                            item['headerImgUrl']=res.datas.headImgUrl
                                            setNowItem(item)
                                        })
                                    }}>重置</Button>
                                    <Button style={{marginLeft:'1vw'}} size={'middle'} type='primary' onClick={() => {
                                        let item = { ...nowItem };
                                        item.skuInfoId=''
                                        setNowItem(item)

                                    }}>取消关联</Button>
                                </div>

                            </Form.Item>
                            <Form.Item label='商品标题' name="specs">
                                <div>
                                    <TextArea placeholder='请输入' value={nowItem.title} showCount maxLength={150} style={{ width:'100%', height: 120 }}
                                              onChange={(e)=>{onChange_Form_Name('title', e.target.value) }} />

                                    {/*<Input*/}
                                    {/*    placeholder='请输入'*/}
                                    {/*    type="text"*/}
                                    {/*    value={nowItem.specs}*/}
                                    {/*    onChange={(e) => { onChange_Form_Name('specs', e.target.value) }}*/}
                                    {/*/>*/}
                                </div>
                            </Form.Item>
                            <Form.Item label='商品规格'  name="specs">
                                <div>
                                    <Input
                                        placeholder='请输入'
                                        type="text"
                                        value={nowItem.specs}
                                        onChange={(e) => { onChange_Form_Name('specs', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label='优惠后价'  name="price">
                                <div>
                                    <Input
                                        placeholder='请输入'
                                        type="text"
                                        value={nowItem.price}
                                        onChange={(e) => { onChange_Form_Name('price', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label='优惠前价'  name="originalPrice">
                                <div>
                                    <Input
                                        placeholder='请输入'
                                        type="text"
                                        value={nowItem.originalPrice}
                                        onChange={(e) => { onChange_Form_Name('originalPrice', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item label="所属品牌" name="brandAlias">
                                <div className={styles.fromItem}>
                                    <div style={{ width: '100%' }} >
                                        <Input value={nowItem.brandAlias} placeholder='请尽量输入英文名' onChange={(e) => { onChange_Form_Name('brandAlias', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item label="优惠力度" colon={true} name="recommendLevel">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('recommendLevel', e.target.value) }} value={nowItem.recommendLevel}>
                                            <Radio value={0}>普通</Radio>
                                            <Radio value={1}>弱推</Radio>
                                            <Radio value={2}>中推</Radio>
                                            <Radio value={3}>强推</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>


                            <Form.Item label="优惠渠道"   name="channel">
                                <div className={styles.fromItem}>
                                    <div>
                                        <Radio.Group onChange={(e) => { onChange_Form_Name('channel', e.target.value) }} value={nowItem.channel}>
                                            <Radio value={'天猫'}>天猫</Radio>
                                            <Radio value={'京东'}>京东</Radio>
                                            <Radio value={'官微'}>官微</Radio>
                                            <Radio value={'CDF'}>CDF</Radio>
                                            <Radio value={'PDD'}>PDD</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item  label='优惠链接'   name="outLink">
                                <div>
                                    <Input
                                        placeholder='请输入'
                                        type="text"
                                        suffix={<Image width='20px' height='20px' src={nowItem.outLinkQrcode} style={{background:'#222'}}></Image>}
                                        value={nowItem.outLink}
                                        onChange={(e) => { onChange_Form_Name('outLink', e.target.value) }}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item label='优惠标签'  name="couponDesc">
                                <div className={styles.fromItem}>
                                    <div style={{ width:'100%' }} >
                                        <Input placeholder='请输入' value={nowItem.couponDesc} onChange={(e) => { onChange_Form_Name('couponDesc', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label='优惠说明'  name="couponContent">
                                <div className={styles.fromItem}>
                                    <div style={{ width:'100%' }} >
                                        <TextArea placeholder='请输入' value={nowItem.couponContent} showCount maxLength={150} style={{ width:'100%', height: 120 }} onChange={(e)=>{onChange_Form_Name('couponContent', e.target.value) }} />
                                    </div>
                                </div>
                            </Form.Item>






                            {/*<Form.Item label="活动类型"  labelCol={{span:24}}  name="activityType">*/}
                            {/*    <div className={styles.fromItem}>*/}
                            {/*        <div>*/}
                            {/*            <Checkbox.Group onChange={(e) => { onChange_Form_Name('activityType', e) }} value={nowItem.activityType}>*/}
                            {/*                <Row>*/}
                            {/*                    <Checkbox value={'满减'}>满减</Checkbox>*/}
                            {/*                    <Checkbox value={'满赠'}>满赠</Checkbox>*/}
                            {/*                    <Checkbox value={'新客'}>新客</Checkbox>*/}
                            {/*                    <Checkbox value={'复购'}>复购</Checkbox>*/}
                            {/*                </Row>*/}
                            {/*                <Row>*/}
                            {/*                    <Checkbox value={'试用'}>试用</Checkbox>*/}
                            {/*                    <Checkbox value={'拼团'}>拼团</Checkbox>*/}
                            {/*                    <Checkbox value={'积分'}>积分</Checkbox>*/}
                            {/*                    <Checkbox value={'上新'}>上新</Checkbox>*/}
                            {/*                </Row>*/}
                            {/*            </Checkbox.Group>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Form.Item>*/}
                            <Form.Item label='优惠开始' name="startTime">
                                <div className={styles.fromItem}>
                                    <DatePicker placeholder='（选填）' value={nowItem.startTime ? moment(nowItem.startTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('startTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />
                                </div>
                            </Form.Item>
                            <Form.Item label='优惠结束' name="expirationTime">
                                <div className={styles.fromItem}>
                                    <DatePicker  placeholder='（选填）' value={nowItem.expirationTime ? moment(nowItem.expirationTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('expirationTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />
                                </div>
                            </Form.Item>
                            <Form.Item label='提示：' name="statusExplain">
                                {(nowItem.status==1 || nowItem.status==-1) && <div className={styles.statusExplain}>
                                    {nowItem.statusExplain}
                                </div>}
                            </Form.Item>


                        </Form>
                    </Col>
                    <Col span={8}>
                        <ArticleManege_Editor ref={inputRef} Data={nowItem ? nowItem.content?nowItem.content:'<p></p>' : null} getTbCode={(code: number) => { getNowItemCode(code) }} />
                    </Col>

                    <Col span={6}>
                        <Form.Item  name="headerImgUrl">
                            <div className={styles.fromItem}>
                                <div>
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        beforeUpload={(file) => beforeUpload(file)}
                                    >
                                        {nowItem && nowItem.headerImgUrl ? <img src={nowItem.headerImgUrl} alt="avatar" style={{ width: '100px', height: '100px' }} /> :
                                            <PlusOutlined />}
                                    </Upload>
                                </div>
                                {nowItem && nowItem.headerImgUrl && <Image src={nowItem.headerImgUrl} alt="avatar" style={{ width: '100px', height: '100px' }} /> }

                            </div>
                        </Form.Item>


                        <Form.Item  name="oneClassify">
                            {nowItem && <Select showSearch optionFilterProp="children" placeholder='商品一级分类'
                                     defaultValue={nowItem.oneClassify} value={nowItem.oneClassify}
                                     onChange={(value: number) => {
                                         onChange_Form_Name('oneClassify', value)
                                     }}>
                                {typeList && typeList.map((item: any, index: number) => {
                                    return (
                                        <Option value={index} key={item}>{item.classify}</Option>
                                    )
                                })
                                }
                            </Select>}
                        </Form.Item>
                        <Form.Item  name="twoClassify" >
                            {nowItem && <Select showSearch optionFilterProp="children" placeholder='商品二级分类' defaultValue={nowItem.twoClassify} value={nowItem.twoClassify}   onChange={(value: number) => { onChange_Form_Name('twoClassify', value) }}>
                                {twoTypeList && twoTypeList.map((item: any,index:number) => {
                                    return (
                                        <Option value={index} key={item}>{item.classify }</Option>
                                    )
                                })
                                }
                            </Select>}
                        </Form.Item>
                        <Form.Item  name="shareText">
                            <div className={styles.fromItem}>
                                <div style={{ width:'100vw' }} >
                                    <textarea style={{ width:'100%',height:'15vw' }} placeholder='群分享文案' value={nowItem.shareText} onChange={(e) => { onChange_Form_Name('shareText', e.target.value) }} />
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item name="shareTime">
                            <div className={styles.fromItem}>
                                <div  >
                                    <DatePicker  placeholder='群分享时间' value={nowItem.shareTime ? moment(nowItem.shareTime) : null} showTime onChange={(value, dateString) => {
                                        onChange_Form_Name('shareTime', moment(value).format('YYYY-MM-DD HH:mm:ss'))
                                    }} />                                    </div>
                            </div>
                        </Form.Item>
                        <div className={styles.btnCol}>
                            <Button size={'middle'} type='primary' onClick={() => { setVisible(true) }}>转给他人</Button>
                            <Button  size={'middle'} type='primary' onClick={() => { addItem('2') }}>保存并上架</Button>
                            <Button size={'middle'} type='primary' onClick={() => { addItem('-2') }}>忽略该优惠</Button>
                        </div>

                    </Col>

                </Row>
                <Modal title="乾坤大挪移" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <Radio.Group onChange={(e) => { setAdminUserId(e.target.value)}} value={adminUserId}>
                        {userLists && userLists.map((e:any)=>(<Radio style={{}} value={e.id}>{e.name}</Radio>))}
                    </Radio.Group>
                </Modal>
            </div>
        )
    }
    return (
        <div className={styles.articleManege}>
            {
                <React.Fragment>
                    {renderHeader()}
                    {renderNav()}
                </React.Fragment>
            }

        </div>
    )
};
function reloads(){
    console.log('-----')
}
export default ArticleManege;
