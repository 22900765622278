/**
 * request 网络请求工具
 */
import { extend } from "umi-request";
import { urlPrefix } from "../config";
import { message, notification } from 'antd';
import { useHistory } from "react-router";

export interface resultType {
  code: string
  datas: any
  message: string
}

export const _getParam = (params: any) => {
  return Object.keys(params).map((key) => {
    if (params[key] === 0 || params[key] || params[key] === false) {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    }
  }).join('&');
};


const codeMessage:any = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  405: '请求方法不被允许。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};
/**
 * 异常处理程序
 */

const errorHandler = (error:any) => {
  const { response } = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    notification.error({
      message: `请求错误 ${status}: ${url}`,
      description: errorText,
    });
  } else if (!response) {
    notification.error({
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }
  return response;
};

export const whyRequest = extend({
  prefix: `${urlPrefix}`, //urlPrefix
  errorHandler,
  // 默认错误处理    
  crossOrigin: true, // 开启CORS跨域
  // 默认错误处理
  // credentials: 'include', // 默认请求是否带上cookie
});

// 中间件，对请求前添加 userId token 的基础参数
whyRequest.interceptors.request.use((url:string, options:any) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    token:localStorage.getItem("token")
  };
  return {
    url: `${url}`,
    options: { ...options, headers },
  }
});

// 全局相应拦截
export const responseInterceptor = (response:resultType)=> {
  const successCode = "000"
  const warningCode:any = {
    "-002": "访问频繁，请稍后再试",
    "-001": "系统开小差了",
    "001": "系统错误",
    "002": "参数错误",
    "003": "用户未登录",
    "004": "越权操作",
    "005": "失败",
    "006": "记录不存在",
    "007": "重复操作",
    "008": "超过时间限制",
    "009": "该用户无权操作",
    "010": "token超时",
    "011": "token失败",
    "012": "账号已经在别处登录或是IP发生了变化,请重新登录",
  };
  const goLoadingCode = ["010","011","012"]
  return new Promise<resultType> ( (resolve,reject) => {
    if (response.code == successCode) {
      resolve(response)
    }else{
      const errorText = codeMessage[response.code];
      if (goLoadingCode.includes(response.code)) {
        message.error(warningCode[response.code])
        window.localStorage.removeItem('token');
        useHistory().push('/login')
      }else{
        notification.error({
          message: `- - ! 错误`,
          description: response.message,
        });
      }
      reject(errorText)
    }
  } );
  
};

export default whyRequest;
